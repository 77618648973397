import dayjs from "dayjs";
import axios from "axios";

const LOCALE_CONFIG_MAPPING = {
  "en-US": {
    currency: "$",
    inputFormat: "MM/dd/yyyy",
    displayFormat: "MM/DD/YYYY",
  },
  "en-EU": {
    currency: "€",
    inputFormat: "dd/MM/yyyy",
    displayFormat: "DD/MM/YYYY",
  },
  "en-GB": {
    currency: "£",
    inputFormat: "dd/MM/yyyy",
    displayFormat: "DD/MM/YYYY",
  },
  // add more mappings as needed
};

export default {
  inject: ["publicSiteSettings", "labels"],
  methods: {
    submitForm(e) {
      var minimumNotice =
        parseInt(
          this.publicSiteSettings.fields.aeroParkerParkingMinimumNotice
        ) || 0;
      var minimumTime = this.publicSiteSettings.fields.timezone
        ? new Date(this.publicSiteSettings.fields.timezone.currentTime)
        : new Date();
      minimumTime.setMinutes(minimumTime.getMinutes() + minimumNotice);
      if (
        this.entryDateAndTime < minimumTime ||
        this.exitDateAndTime < minimumTime
      ) {
        var timeValidationMessage = "";
        if (minimumNotice === 0) {
          timeValidationMessage = this.labels.validationTimeGeneric;
        } else if (minimumNotice % 60 === 0) {
          timeValidationMessage = this.labels.validationTimeHours.replace(
            "{0}",
            minimumNotice / 60
          );
        } else {
          timeValidationMessage = this.labels.validationTimeMinutes.replace(
            "{0}",
            minimumNotice
          );
        }
        alert(timeValidationMessage);
        e.preventDefault();
      } else if (this.exitDateAndTime <= this.entryDateAndTime) {
        alert(this.labels.validationTimeOrder);
        e.preventDefault();
      } else {
        e.target.action = this.postUrl;
      }
    },
    updateTeaserPrice() {
      if (this.entryDate > this.exitDate) {
        this.exitDate = this.entryDate;
      }
      if (this.exitDateAndTime > this.entryDateAndTime) {
        axios
          .get(
            process.env.VUE_APP_REMOTEBASEURL +
              "/api/AeroparkerBookingApi/RetrieveLowestParkingPrice?iataCode=" +
              process.env.VUE_APP_IATACODE +
              "&entryDateTime=" +
              dayjs(this.entryDateAndTime).format("YYYY-MM-DD HH:mm") +
              "&exitDateTime=" +
              dayjs(this.exitDateAndTime).format("YYYY-MM-DD HH:mm")
          )
          .then((response) => {
            this.teaserPrice = response.data;
          });
      } else {
        this.teaserPrice = "";
      }
    },
    updateTerminal() {
      this.terminal = this.airlineTerminal.split("_")[1]; // TODO - work out better way of passing independent terminal value. May need different select dropdown
    },
    buildTimeslots() {
      // Build Timeslots
      var slotStart =
        this.publicSiteSettings.fields.aeroParkerParkingSlotStart || "00:00";
      var slotStartHour = parseInt(slotStart.split(":")[0]) || 0;
      var slotStartMins = parseInt(slotStart.split(":")[1]) || 0;
      var slotEnd =
        this.publicSiteSettings.fields.aeroParkerParkingSlotEnd || "24:01";
      var slotEndHour = parseInt(slotEnd.split(":")[0]) || 23;
      var slotEndMins = parseInt(slotEnd.split(":")[1]) || 59;
      var slotLength =
        parseInt(this.publicSiteSettings.fields.aeroParkerParkingSlotLength) ||
        60;
      var startOfDayAdjustment = true;
      var formatString =
        this.publicSiteSettings.fields.twelveHourClockFormat || false
          ? "h:mm a"
          : "HH:mm";
      var timeslots = [];
      for (let hours = slotStartHour; hours <= slotEndHour; hours++) {
        for (
          let mins = hours == slotStartHour ? slotStartMins : 0;
          mins < (hours == slotEndHour ? slotEndMins : 60);
          mins += slotLength
        ) {
          let friendlyStartTime =
            String("00" + hours).slice(-2) +
            ":" +
            String("00" + mins).slice(-2);
          if (friendlyStartTime == "00:00" || friendlyStartTime == "24:00") {
            friendlyStartTime = startOfDayAdjustment ? "00:01" : "23:59";
            startOfDayAdjustment = false;
          }
          timeslots.push(friendlyStartTime);
        }
      }
      var timeslotsFriendly = timeslots.map(function (time) {
        return {
          id: time,
          text: dayjs("1970-01-01 " + time).format(formatString),
        };
      });
      return timeslotsFriendly;
    },
    buildLoungeTimeSlots() {
      var slotStart =
        this.publicSiteSettings.fields.aeroParkerLoungeStartTime || "00:00";
      var slotStartHour = parseInt(slotStart.split(":")[0]) || 0;
      var slotStartMins = parseInt(slotStart.split(":")[1]) || 0;
      var slotEnd =
        this.publicSiteSettings.fields.aeroParkerLoungeEndTime || "24:01";
      var slotEndHour = parseInt(slotEnd.split(":")[0]) || 23;
      var slotEndMins = parseInt(slotEnd.split(":")[1]) || 59;
      var slotLength =
        parseInt(this.publicSiteSettings.fields.aeroParkerLoungeSlotLength) ||
        60;
      var startOfDayAdjustment = true;
      var formatString =
        this.publicSiteSettings.fields.twelveHourClockFormat || false
          ? "h:mm a"
          : "HH:mm";
      var timeslots = [];
      for (let hours = slotStartHour; hours <= slotEndHour; hours++) {
        for (
          let mins = hours == slotStartHour ? slotStartMins : 0;
          mins < (hours == slotEndHour ? slotEndMins : 60);
          mins += slotLength
        ) {
          let friendlyStartTime =
            String("00" + hours).slice(-2) +
            ":" +
            String("00" + mins).slice(-2);
          if (friendlyStartTime == "00:00" || friendlyStartTime == "24:00") {
            friendlyStartTime = startOfDayAdjustment ? "00:01" : "23:59";
            startOfDayAdjustment = false;
          }
          timeslots.push(friendlyStartTime);
        }
      }
      var timeslotsFriendly = timeslots.map(function (time) {
        return {
          id: time,
          text: dayjs("1970-01-01 " + time).format(formatString),
        };
      });
      return timeslotsFriendly;
    },
    buildSecurityTimeSlots() {
      var slotStart =
        this.publicSiteSettings.fields.aeroParkerSecurityStartTime || "00:00";
      var slotStartHour = parseInt(slotStart.split(":")[0]) || 0;
      var slotStartMins = parseInt(slotStart.split(":")[1]) || 0;
      var slotEnd =
        this.publicSiteSettings.fields.aeroParkerSecurityEndTime || "24:01";
      var slotEndHour = parseInt(slotEnd.split(":")[0]) || 23;
      var slotEndMins = parseInt(slotEnd.split(":")[1]) || 59;
      var slotLength =
        parseInt(this.publicSiteSettings.fields.aeroParkerSecuritySlotLength) ||
        60;
      var startOfDayAdjustment = true;
      var formatString =
        this.publicSiteSettings.fields.twelveHourClockFormat || false
          ? "h:mm a"
          : "HH:mm";
      var timeslots = [];
      for (let hours = slotStartHour; hours <= slotEndHour; hours++) {
        for (
          let mins = hours == slotStartHour ? slotStartMins : 0;
          mins < (hours == slotEndHour ? slotEndMins : 60);
          mins += slotLength
        ) {
          let friendlyStartTime =
            String("00" + hours).slice(-2) +
            ":" +
            String("00" + mins).slice(-2);
          if (friendlyStartTime == "00:00" || friendlyStartTime == "24:00") {
            friendlyStartTime = startOfDayAdjustment ? "00:01" : "23:59";
            startOfDayAdjustment = false;
          }
          timeslots.push(friendlyStartTime);
        }
      }
      var timeslotsFriendly = timeslots.map(function (time) {
        return {
          id: time,
          text: dayjs("1970-01-01 " + time).format(formatString),
        };
      });
      return timeslotsFriendly;
    },
    buildOptionsAirline() {
      if (!this.publicSiteSettings.fields.activeAirlines) {
        return [];
      }

      return this.publicSiteSettings.fields.activeAirlines.map((airline) => {
        return {
          id:
            airline.system.id +
            "_" +
            airline.fields.terminalPicker.fields.terminalID,
          text: airline.system.name,
        };
      });
    },
  },
  computed: {
    reactivePublicSiteSettings() {
      // This computed property will update whenever `publicSiteSettings` changes
      return this.publicSiteSettings;
    },
    localeConfig() {
      // Return a default configuration if locale not found.
      return (
        LOCALE_CONFIG_MAPPING[this.publicSiteSettings.fields.globalLocale] ||
        LOCALE_CONFIG_MAPPING["en-US"]
      );
    },
    currency() {
      return this.localeConfig.currency;
    },
    inputFormat() {
      return this.localeConfig.inputFormat;
    },
    displayFormat() {
      return this.localeConfig.displayFormat;
    },
    entryDateAndTime() {
      const [hours, minutes] = this.entryTime.split(":");
      return new Date(
        this.entryDate.getFullYear(),
        this.entryDate.getMonth(),
        this.entryDate.getDate(),
        hours,
        minutes
      );
    },
    exitDateAndTime() {
      const [hours, minutes] = this.exitTime.split(":");
      return new Date(
        this.exitDate.getFullYear(),
        this.exitDate.getMonth(),
        this.exitDate.getDate(),
        hours,
        minutes
      );
    },
    entryDateNormalised: function () {
      return dayjs(this.entryDate).format(this.displayFormat);
    },
    exitDateNormalised: function () {
      return dayjs(this.exitDate).format(this.displayFormat);
    },
    postUrl() {
      return this.publicSiteSettings.fields.aeroParkerParkingUrl;
    },
  },
  data() {
    return {
      entryDate: new Date(),
      exitDate: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000),
      entryTime: "",
      exitTime: "",
      promoCode: "",
      terminal: -1,
      airlineTerminal: "",
      optionsAirline: this.buildOptionsAirline(),
      teaserPrice: "",
      optionsTime: this.buildTimeslots(),
    };
  },
};
