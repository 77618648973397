import { defineStore } from "pinia"

export const useBookingWizardStore = defineStore('booking-wizard-store', {
  state: () => ({
    step: 0,
    basket: null,
    entryDate: null,
    exitDate: null,
    parkingAvailabilityWindowKey: null
  }),
  actions:{
    nextStep(){
      this.step++;
    },
    previousStep(){
      this.step--;
    },
    setStep(index){
      this.step = index;
    },
    addBasket(currentBasket){
      this.basket = currentBasket;
    },
    setEntryDate(input){
      this.entryDate = input;
    },
    setExitDate(input){
      this.exitDate = input;
    },
    setParkingAvailabilityWindowKey(input){
      this.parkingAvailabilityWindowKey = input;
    }
  },
});