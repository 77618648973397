<template>
  <div class="varient-input">
    <p class="varient-input__title">{{ name }}: {{ currency }}{{ price }}</p>

    <div class="varient-input__input-wrapper">
      <button
        class="varient-input__button varient-input__button--decrement"
        @click.prevent="decrementItemQuantity()"
      >
        <span>-</span>
      </button>
      <input
        class="varient-input__input"
        :min="minQty"
        :max="maxQty"
        type="number"
        v-model="quantity"
      />
      <button
        class="varient-input__button varient-input__button--increment"
        @click.prevent="incrementItemQuantity()"
      >
        <span>+</span>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.varient-input {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  justify-content: space-between;
  width: 100%;

  &__title {
    margin: 0;
  }

  &__input-wrapper {
    display: grid;
    grid-template-columns: 50px 50px 50px;
  }

  &__button {
    display: grid;
    place-items: center;
    background-color: $skin-colour-secondary;
    font-size: 32px;
    color: #ffffff;

    &--decrement {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;

      span {
        transform: translateY(-2px);
      }
    }

    &--increment {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

  &__input {
    text-align: center;
  }
}
</style>

<script>
import { ref } from "vue";
import { useBookingWizardStore } from "../../stores/bookingwizardstore";
import axios from "axios";

export default {
  name: "VariantInput",
  props: [
    "productId",
    "name",
    "maxQty",
    "minQty",
    "price",
    "value",
    "currency",
    "productType",
  ],
  setup(props) {
    const quantity = ref(props.value);
    return { quantity };
  },
  methods: {
    incrementItemQuantity() {
      var isValid = false;

      if (this.$props.name == "Child") {
        isValid = this.checkForAdultTicket(this.$props.productId);
      } else {
        isValid = true;
      }

      if (isValid) {
        this.quantity++;
        this.UpdateBasket(this.quantity);
      }
    },
    decrementItemQuantity() {
      if (this.quantity > 0) {
        this.quantity--;
        this.UpdateBasket(this.quantity);
      }
    },
    UpdateBasket(qty) {
      const wizardStore = useBookingWizardStore();

      var entryDateTime = wizardStore.entryDate;
      var exitDateTime = wizardStore.exitDate;

      var request = {
        arrivalDateTime: entryDateTime.toISOString(),
        returnDateTime: exitDateTime.toISOString(),
        type: this.$props.productType,
        iataCode: process.env.VUE_APP_IATACODE,
        parkingAvailabilityWindowKey: wizardStore.parkingAvailabilityWindowKey,
        basket: wizardStore.basket,
        productId: this.$props.productId,
        variantId: null,
        variantQuantity: qty,
        variantName: this.$props.name,
      };

      axios
        .post(
          process.env.VUE_APP_REMOTEBASEURL +
            "/api/bookingapi/AddOrUpdateBasket",
          request,
        )
        .then(function (response) {
          wizardStore.addBasket(response.data.basket);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    checkForAdultTicket(productId) {
      const wizardStore = useBookingWizardStore();

      if (!wizardStore.basket || !wizardStore.basket.products) {
        return false;
      }

      // Check if any product in the basket has a variant named "Adult" with quantity > 0
      const hasAdultTicket = wizardStore.basket.products
        .filter((x) => x.id == productId)
        .some((product) =>
          product.variants.some(
            (variant) =>
              variant.variantName === "Adult" && variant.quantity > 0,
          ),
        );

      if (!hasAdultTicket) {
        alert("Adult 18+ must accompany children");
      }

      return hasAdultTicket;
    },
  },
};
</script>
