<template>
  <section class="panel-padding">
    <div class="mailing-list">
      <div class="inner">
        <header class="mailing-list__intro">
          <h2 v-if="componentData.fields.header" class="mailing-list__heading">
            {{ componentData.fields.header }}
          </h2>
          <div
            v-if="componentData.fields.introField"
            v-html="componentData.fields.introField"
          ></div>
        </header>
        <form method="post" v-on:submit="submitForm">
          <div class="mailing-list_grid">
            <div v-if="componentData.fields.categoryField">
              <label for="categoryDropdownOption">
                {{ componentData.fields.categoryField }}
              </label>
              <Select2
                :options="componentData.fields.categoryDropdownOptions"
                :settings="{
                  placeholder: componentData.fields.categoryDropdownOptions[0],
                }"
                v-model="categoryDropdownOption"
              >
              </Select2>
            </div>

            <div v-if="componentData.fields.subCategoryField">
              <label for="subCategoryDropdownOption">
                {{ componentData.fields.subCategoryField }}
              </label>
              <Select2
                :options="componentData.fields.subCategoryDropdownOptions"
                :settings="{
                  placeholder:
                    componentData.fields.subCategoryDropdownOptions[0],
                }"
                v-model="subCategoryDropdownOption"
              >
              </Select2>
            </div>

            <div v-if="componentData.fields.locationField">
              <label for="locationDropdownOption">
                {{ componentData.fields.locationField }}
              </label>
              <Select2
                :options="componentData.fields.locationDropdownOptions"
                :settings="{
                  placeholder: componentData.fields.locationDropdownOptions[0],
                }"
                v-model="locationDropdownOption"
              >
              </Select2>
            </div>

            <div v-if="componentData.fields.subjectField">
              <label for="subjectField">
                {{ componentData.fields.subjectField }}
              </label>
              <input
                id="subjectField"
                type="text"
                :placeholder="componentData.fields.subjectField"
                v-model="subjectField"
              />
            </div>

            <div v-if="componentData.fields.dateField">
              <label for="dateField">
                {{ componentData.fields.dateField }}
              </label>
              <DatePicker
                v-model="dateField"                
                :masks="{ input: this.displayFormat }"
                class="datepicker"
                :popover="{ visibility: 'focus' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    :value="inputValue || this.labels.labelDate"
                    v-on="inputEvents"
                  />
                </template>
              </DatePicker>
            </div>

            <div v-if="componentData.fields.flightNumberField">
              <label for="flightNumberField">
                {{ componentData.fields.flightNumberField }}
              </label>
              <input
                id="flightNumberField"
                type="text"
                :placeholder="componentData.fields.flightNumberField"
                v-model="flightNumberField"
              />
            </div>

            <div v-if="componentData.fields.commentsField">
              <label for="commentsField">
                {{ componentData.fields.commentsField }}
              </label>
              <textarea
                id="commentsField"
                type="textarea"
                :placeholder="componentData.fields.commentsField"
                v-model="commentsField"
                rows="10"
              ></textarea>
            </div>
          </div>
          <div class="mailing-list_grid">
            <p
              v-if="componentData.fields.contactInformationTitle"
              class="mailing-list__heading--sm"
            >
              {{ componentData.fields.contactInformationTitle }}
            </p>

            <div></div>

            <div>
              <label for="name">
                {{ componentData.fields.firstNameField }}
              </label>
              <input
                id="name"
                type="text"
                :placeholder="componentData.fields.lastNameField"
                v-model="firstNameField"
              />
            </div>
            <div>
              <label for="name">
                {{ componentData.fields.lastNameField }}
              </label>
              <input
                id="name"
                type="text"
                :placeholder="componentData.fields.firstNameField"
                v-model="lastNameField"
              />
            </div>
            <div>
              <label for="email">
                {{ componentData.fields.emailField }}
              </label>
              <input
                id="emailField"
                type="email"
                :placeholder="componentData.fields.emailField"
                v-model="emailField"
              />
            </div>

            <div v-if="componentData.fields.phoneNumberField">
              <label for="phone">
                {{ componentData.fields.phoneNumberField }}
              </label>
              <input
                id="phone"
                type="tel"
                :placeholder="componentData.fields.phoneNumberField"
                v-model="phoneField"
              />
            </div>

            <div v-if="componentData.fields.streetField">
              <label for="streetField">
                {{ componentData.fields.streetField }}
              </label>
              <input
                id="streetField"
                type="text"
                :placeholder="componentData.fields.streetField"
                v-model="streetField"
              />
            </div>

            <div v-if="componentData.fields.cityField">
              <label for="cityField">
                {{ componentData.fields.cityField }}
              </label>
              <input
                id="cityField"
                type="text"
                :placeholder="componentData.fields.cityField"
                v-model="cityField"
              />
            </div>

            <div v-if="componentData.fields.countyField">
              <label for="countyField">
                {{ componentData.fields.countyField }}
              </label>
              <input
                id="countyField"
                type="text"
                :placeholder="componentData.fields.countyField"
                v-model="countyField"
              />
            </div>

            <div v-if="componentData.fields.countryField">
              <label for="countryField">
                {{ componentData.fields.countryField }}
              </label>
              <input
                id="countryField"
                type="text"
                :placeholder="componentData.fields.countryField"
                v-model="countryField"
              />
            </div>

            <div v-if="componentData.fields.postCodeField">
              <label for="postCodeField">
                {{ componentData.fields.postCodeField }}
              </label>
              <input
                id="postCodeField"
                type="text"
                :placeholder="componentData.fields.postCodeField"
                v-model="postCodeField"
              />
            </div>
          </div>

          <div class="grid__full checkbox">
            <input
              id="mailingListTermsConditions"
              type="checkbox"
              v-model="mailingListTermsConditions"
            />
            <label
              for="mailingListTermsConditions"
              v-html="componentData.fields.tickBoxCopy"
            ></label>
            <div
              v-if="componentData.fields.introField"
              v-html="componentData.fields.termsAndConditionsCopy"
            ></div>
          </div>
          <div>
            <input
              type="submit"
              class="btn btn--primary"
              :value="componentData.fields.buttonText"
            />
          </div>

          <!-- Error Messages -->
          <div
            v-if="errorVisible"
            :class="{
              'mailing-list__success': errorClass,
              'mailing-list__error': !errorClass,
            }"
          >
            <ul>
              <li v-for="(error, index) in errorMessages" :key="index">
                {{ error }}
              </li>
            </ul>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import Select2 from "vue3-select2-component";
import axios from "axios";
import { DatePicker } from "v-calendar";

import parkingForm from "../../mixins/parkingForm";

export default {
  name: "StaticCombinedEnquiryForm",
  props: ["componentData"],
  mixins: [parkingForm],
  data() {
    return {
      isValidResponse: false,
      errorVisible: false,
      errorClass: false,
      categoryDropdownOption:
        this.componentData.fields.categoryDropdownOptions[0] !== null
          ? this.componentData.fields.categoryDropdownOptions[0]
          : "",
      subCategoryDropdownOption:
        this.componentData.fields.subCategoryDropdownOptions[0] !== null
          ? this.componentData.fields.subCategoryDropdownOptions[0]
          : "",
      locationDropdownOption:
        this.componentData.fields.locationDropdownOptions[0] !== null
          ? this.componentData.fields.locationDropdownOptions[0]
          : "",
      subjectField: "",
      dateField: "",
      flightNumberField: "",
      commentsField: "",
      firstNameField: "",
      lastNameField: "",
      emailField: "",
      phoneField: "",
      streetField: "",
      cityField: "",
      countyField: "",
      countryField: "",
      postCodeField: "",
      toEmailAddress: this.componentData.fields.toEmailAddress,
      mailingListTermsConditions: false,
      cResponse: this.calculatedResponse,
    };
  },
  components: {
    Select2,
    DatePicker,
  },
  methods: {
    submitForm(e) {
      e.preventDefault();

      // Reset error states
      this.errorMessages = []; // Change from a single string to an array
      this.errorVisible = false;
      this.errorClass = false;

      // Check if all the string fields are filled in
      const requiredFields = [
        this.subjectField,
        this.dateField.toString(),
        this.flightNumberField,
        this.commentsField,
        this.firstNameField,
        this.lastNameField,
        this.emailField,
        this.phoneField,
        this.streetField,
        this.cityField,
        this.countyField,
        this.countryField,
        this.postCodeField,
      ];

      const emptyFields = requiredFields.filter((field) => !field.trim());
      console.log(emptyFields);

      // If there are any empty fields, add a generic validation message
      if (emptyFields.length > 0) {
        this.errorMessages.push("Please fill in all the fields in the form.");
      }

      // Validate Terms & Conditions Checkbox with cleaned and properly quoted label
      if (!this.mailingListTermsConditions) {
        const rawTermsLabel =
          this.componentData?.fields?.termsErrorMessage ||
          "Terms and Conditions";
        const termsLabel = `"${this.stripHtmlTags(rawTermsLabel).trim()}"`; // Trim any extra whitespace
        this.errorMessages.push(`${termsLabel} checkbox is required.`);
      }

      // If any errors exist, show them and stop form submission
      if (this.errorMessages.length > 0) {
        this.errorVisible = true;
        this.errorClass = false;
        return;
      }
      axios
        .post(
          process.env.VUE_APP_REMOTEBASEURL +
            "/api/FormApi/AddToStaticCombinedEnquiryForm",
          {
            categoryDropdownOption: this.categoryDropdownOption,
            subCategoryDropdownOption: this.subCategoryDropdownOption,
            locationDropdownOption: this.locationDropdownOption,
            subject: this.subjectField,
            dateField: this.dateField.toString(),
            flightNumber: this.flightNumberField,
            message: this.commentsField,
            firstName: this.firstNameField,
            lastName: this.lastNameField,
            email: this.emailField,
            phone: this.phoneField,
            street: this.streetField,
            city: this.cityField,
            county: this.countyField,
            countryField: this.countryField,
            postCodeField: this.postCodeField,
            componentId: this.componentData.system.id,
            toEmailAddress: this.toEmailAddress,
            iataCode: process.env.VUE_APP_IATACODE,
          }
        )
        .then((response) => {
          if (response.data) {
            if (this.componentData.fields.thanksPageRedirect !== undefined) {
              window.location.href =
                this.componentData.fields.thanksPageRedirect.url;
            }
            this.errorMessages = ["Your email has successfully been sent!"];
            this.errorVisible = true;
            this.errorClass = true;
          } else {
            this.errorMessages = [
              "Sorry, your email has not been sent, please try again later.",
            ];
            this.errorVisible = true;
            this.errorClass = false;
          }
        });
    },
    stripHtmlTags(input) {
      return input ? input.replace(/<\/?[^>]+(>|$)/g, "").trim() : "";
    },
    validateEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
  },
  mounted() {
    this.isValidResponse = true;
  },
};
</script>

<style lang="scss">
.mailing-list {
  color: $promo-module-color;
  background-color: $skin-colour-primary;
  padding: 4rem 0;

  &__heading--sm {
    @include breakpoint($tablet-sml) {
      font-size: 2.6rem;
    }
  }

  &_grid {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
    margin-top: 3rem;

    @include breakpoint($tablet-sml) {
      grid-template-columns: 1fr 1fr;
      margin-top: 6rem;
      grid-column-gap: 4rem;
      grid-row-gap: 2rem;
    }
  }

  input,
  p {
    margin-bottom: 0;
  }
  
  &__success {
    margin-top: 2rem;
  }

  &__error {
    // background: #f1cece;
    // border: 1px solid #ff1101;
    // color: #ff1101;
    background-color: #ef4444;
    border: 2px solid #dc2626;
    color: #ffffff;
    display: block;
    font-size: 14px;
    padding: 1rem 2rem;
    text-align: center;
    margin-top: 2rem;

    @include breakpoint($tablet-sml) {
      display: inline-block;
    }
  }

  &_-form-group {
    color: #ffffff;
  }
}
</style>
