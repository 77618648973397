<template>
  <section class="hotels-widget">
    <div class="form form--inline">
      <fieldset>
        <div class="grid">
          <div class="grid__sixth">
            <label for="entryDate">{{ labels.hotelBookingCheckInLabel }}</label>
            <DatePicker
              :min-date="new Date()"
              class="datepicker"
              :popover="{ visibility: 'focus' }"
              is-required
              v-model="entryDate"
              :placeholder="new Date()"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  :value="inputValue"
                  v-on="inputEvents"
                  :readonly="hideKeyboardOnMobile()"
                />
              </template>
            </DatePicker>
          </div>

          <div class="grid__sixth">
            <label for="exitDate">{{ labels.hotelBookingCheckOutLabel }}</label>
            <DatePicker
              v-model="exitDate"
              :min-date="entryDate"
              class="datepicker"
              :popover="{ visibility: 'focus' }"
              is-required
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  :value="inputValue"
                  v-on="inputEvents"
                  :readonly="hideKeyboardOnMobile()"
                />
              </template>
            </DatePicker>
          </div>

          <div class="grid__sixth">
            <label for="hotelDestination">{{
              labels.hotelBookingDestinationsLabel
            }}</label>
            <Select2
              name="AirportVisitDetails_arrival_hour"
              data-field="hidden-arrival-hour"
              class="selectbox-time"
              :options="destinations"
              v-model="destination"
              :settings="select2Settings"
            />
          </div>

          <div class="grid__rest">
            <button
              @click="submitBookingForm()"
              type="submit"
              class="btn btn--primary"
              tabindex="0"
            >
              {{ labels.hotelBookingSubmitButtonText }}
            </button>
          </div>
        </div>
      </fieldset>
    </div>
  </section>
</template>

<script>
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import parkingForm from "../../mixins/parkingForm";
import dayjs from "dayjs";
import axios from "axios";

export default {
  name: "StickyBookingBarHotelsForm",
  props: {
    hotelPinnedBookingBarSettings: Object,
    selectedLanguage: String,
  },
  components: {
    Select2,
    DatePicker,
  },
  mixins: [parkingForm],
  computed: {
    destinations() {
      if (this.rawDestinations.length > 0) {
        return this.rawDestinations.map((el) => el.cityName);
      } else {
        return [
          {
            text: this.labels.hotelBookingNoDestinationsText,
            value: "",
          },
        ];
      }
    },
    displayFormat() {
      return this.localeConfig.displayFormat;
    },
    select2Settings() {
      return {
        placeholder:
          this.destinations &&
          this.destinations.length > 0 &&
          this.labels.hotelBookingDestinationsLabel !== ""
            ? this.labels.hotelBookingDestinationsLabel
            : "Select Destination",
      };
    },
  },
  created() {
    this.loadDestinations();
  },
  data() {
    const today = new Date();
    return {
      rawDestinations: [],
      bookingUrl: "",
      entryDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm"),
      exitDate: dayjs(new Date()).add(1, "day").format("YYYY-MM-DD HH:mm"),
      destination: "",
      contentNodePickerUrl:
        this.hotelPinnedBookingBarSettings.fields.destinationsContentNodePicker
          ?.system.urlSegment,
    };
  },
  watch: {
    selectedLanguage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadDestinations(); // Re-fetch destinations on language change
      }
    },
    entryDate(newDate) {
      const newExitDate = new Date(newDate);
      newExitDate.setDate(newExitDate.getDate() + 1);
      if (newExitDate > this.exitDate) {
        this.exitDate = newExitDate;
      }
    },
    exitDate(newDate) {
      const newEntryDate = new Date(newDate);
      newEntryDate.setDate(newEntryDate.getDate() - 1);
      if (newEntryDate < this.entryDate) {
        this.entryDate = newEntryDate;
      }
    },
  },
  methods: {
    loadDestinations() {
      const url =
        this.contentNodePickerUrl != undefined
          ? "/" + this.contentNodePickerUrl + "/"
          : "/destinations/";

      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/DestinationApi/GetDestinationsLite?url=${url}&iataCode=${process.env.VUE_APP_IATACODE}&cultureCode=${this.selectedLanguage}`;

      axios
        .get(apiUrl)
        .then((response) => {
          this.rawDestinations = response.data.destinations || [];
        })
        .catch((error) => {
          console.log("No destinations received.");
          console.error(error);
          this.rawDestinations = [];
        });
    },
    hideKeyboardOnMobile() {
      if (document.body.classList.contains("has-overlay")) {
        return "readonly";
      }
      return false;
    },
    submitBookingForm() {
      axios
        .get(
          process.env.VUE_APP_REMOTEBASEURL +
            "/api/HotelBookingApi/RetrieveHotelBooking?destination=" +
            this.destination +
            "&arrivalDateTime=" +
            dayjs(this.entryDate).format("YYYY-MM-DD HH:mm") +
            "&exitDateTime=" +
            dayjs(this.exitDate).format("YYYY-MM-DD HH:mm") +
            "&iataCode=" +
            process.env.VUE_APP_IATACODE
        )
        .then((response) => {
          this.bookingUrl = response.data;
          window.open(this.bookingUrl);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
