<template>
  <div class="ancillary-products">
    <div class="ancillary-products__header">
      <h2>{{ componentData.fields.displayName }}</h2>
    </div>
    <div class="loader-wrapper" v-if="loading">
      <loader-spinner />
    </div>
    <template v-for="(product, index) in ancillaryProducts" :key="index">
      <div class="ancillary-products__product-wrapper" v-if="!loading">
        <div class="ancillary-products__product">
          <header class="ancillary-products__product-header">
            <h3 class="ancillary-products__product-title">
              {{ product.title }}
            </h3>
          </header>
          <div class="ancillary-products__product-body">
            <img
              src="https://static.aeroparker.com/shopProducts/logos/19/97-9480.jpg"
              alt="Alt text"
            />
            <div class="ancillary-products__product-details">
              <div class="ancillary-products__product-description">
                <div v-html="product.description"></div>
              </div>
              <div class="ancillary-products__product-variants">
                <template
                  v-for="(variant, index) in product.variants"
                  :key="index"
                >
                  <VariantInput
                    :name="variant.variantName"
                    :productId="product.aeroParkerProductId"
                    :price="variant.unitPrice"
                    currency="£"
                    :maxQty="variant.maxQuantity"
                    :minQty="variant.minQuantity"
                    :productType="1"
                    value="0"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.ancillary-products {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  @include breakpoint($tablet) {
    gap: 4rem;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      margin: 0;
    }
  }

  &__product-wrapper {
    width: 100%;
  }

  &__product {
    display: flex;
    flex-direction: column;
  }

  &__product-header {
    display: flex;

    @include breakpoint($tablet) {
      justify-content: flex-start;
    }
  }

  &__product-body {
    display: flex;
    flex-direction: column;
    border: 1px solid #f3f4f6;
    box-shadow:
      0 3px 3px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    overflow: hidden;

    @include breakpoint($tablet) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      gap: 2rem;
      gap: 3rem;
    }
  }

  &__product-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
  }

  &__product-variants {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-end;
    margin-top: 4rem;
  }
}
</style>

<script>
import axios from "axios";

import { useBookingWizardStore } from "../../stores/bookingwizardstore";
import VariantInput from "./VariantInput.vue";
import LoaderSpinner from "../Loading/LoaderSpinner.vue";

export default {
  name: "AncillaryProducts",
  props: ["componentData"],
  components: {
    VariantInput,
    LoaderSpinner,
  },
  setup() {
    const wizardStore = useBookingWizardStore();
    return {
      wizardStore,
    };
  },
  data() {
    return {
      ancillaryProducts: [],
      ancillaryExtras: [],
      parkingUpgrades: [],
      entryDate: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000),
      exitDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      loading: true,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.Search();
      this.SearchForParkingUpgrades();
    },
    async Search() {
      const wizardStore = useBookingWizardStore();

      var entryDateTime = wizardStore.entryDate;
      var exitDateTime = wizardStore.exitDate;

      var request = {
        arrivalDateTime: entryDateTime.toISOString(),
        returnDateTime: exitDateTime.toISOString(),
        type: "Ancillary",
        iataCode: process.env.VUE_APP_IATACODE,
        parkingAvailabilityWindowKey: wizardStore.parkingAvailabilityWindowKey,
        sortBy: this.sortBy,
        basket: wizardStore.basket,
      };

      this.loading = true;

      axios
        .post(
          process.env.VUE_APP_REMOTEBASEURL +
            "/api/bookingapi/GetDisplayProductsFromSearch",
          request,
        )
        .then((response) => {
          if (response != null) {
            this.loading = false;

            this.ancillaryProducts = response.data.products.filter(
              (a) => a.aeroParkerProductType == 1,
            );
            this.ancillaryExtras = response.data.products.filter(
              (a) => a.aeroParkerProductType == 2,
            );
          }
        });
    },
    async SearchForParkingUpgrades() {
      const wizardStore = useBookingWizardStore();

      var entryDateTime = wizardStore.entryDate;
      var exitDateTime = wizardStore.exitDate;

      var request = {
        arrivalDateTime: entryDateTime.toISOString(),
        returnDateTime: exitDateTime.toISOString(),
        type: "ParkingUpgrade",
        iataCode: process.env.VUE_APP_IATACODE,
        parkingAvailabilityWindowKey: wizardStore.parkingAvailabilityWindowKey,
        sortBy: this.sortBy,
        basket: wizardStore.basket,
      };

      axios
        .post(
          process.env.VUE_APP_REMOTEBASEURL +
            "/api/bookingapi/GetDisplayProductsFromSearch",
          request,
        )
        .then((response) => {
          if (response != null) {
            this.parkingUpgrades = response.data.products;
          }
        });
    },
    AddToBasket(product) {
      const wizardStore = useBookingWizardStore();

      var entryDateTime = wizardStore.entryDate;
      var exitDateTime = wizardStore.exitDate;

      var request = {
        arrivalDateTime: entryDateTime.toISOString(),
        returnDateTime: exitDateTime.toISOString(),
        type: "Parking",
        iataCode: process.env.VUE_APP_IATACODE,
        parkingAvailabilityWindowKey: wizardStore.parkingAvailabilityWindowKey,
        basket: wizardStore.basket,
        productId: product.aeroParkerProductId,
        variantId: null,
        variantQuantity: 1,
        variantName: null,
      };

      axios
        .post(
          process.env.VUE_APP_REMOTEBASEURL +
            "/api/bookingapi/AddOrUpdateBasket",
          request,
        )
        .then(function (response) {
          wizardStore.addBasket(response.data.basket);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    canAddExtras(productId) {
      const wizardStore = useBookingWizardStore();

      // Ensure basket and products exist
      if (!wizardStore.basket || !wizardStore.basket.products) {
        return false;
      }

      // Check if any product in the basket has a variant named "Adult" with quantity > 0
      const hasAdult = wizardStore.basket.products
        .filter((x) => x.id == productId)
        .some((product) =>
          product.variants.some(
            (variant) =>
              variant.variantName === "Adult" && variant.quantity > 0,
          ),
        );

      return hasAdult;
    },
  },
};
</script>
