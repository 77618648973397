<template>
  <div class="banner-alert" :class="bannerClass">
    <div class="banner-alert__header">
      <div class="banner-alert__header-left">
        <SvgIcon
          :name="type === 'red' ? 'exclamation-triangle' : 'exclamation-circle'"
        />
        <span class="banner-alert__count"
          >{{ alerts.length }}
          <span class="banner-alert__count-type">{{ type }}</span>
          {{ alerts.length > 1 ? "Alerts" : "Alert" }}
        </span>
      </div>
      <button class="banner-alert__dropdown" @click="toggleAlertBody">
        <span>Show alerts</span>
        <SvgIcon name="chevron-down" class="close-icon" />
      </button>
    </div>
    <div class="banner-alert__content" v-if="showAlertBody">
      <div
        class="banner-alert__body"
        v-for="alert in alerts"
        :key="alert.system.id"
      >
        <p class="banner-alert__alert-text">{{ alert.fields.alertText }}</p>
        <div class="banner-alert__alert-link-wrapper">
          <a
            class="banner-alert__alert-link"
            v-if="alert.fields.link"
            :href="alert.fields.link.url"
          >
            <span>{{ alert.fields.link.name }}</span>
            <SvgIcon name="chevron-down" class="chevron-down" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/Icon";

export default {
  props: {
    color: {
      type: String,
      required: true,
    },
    alerts: {
      type: Array,
      required: true,
    },
  },
  components: {
    SvgIcon,
  },
  computed: {
    bannerClass() {
      return `banner-alert banner-alert--${this.color}`;
    },
  },
  methods: {
    toggleAlertBody() {
      this.showAlertBody = !this.showAlertBody;
    },
  },
  data() {
    return {
      showAlertBody: false,
      type: this.color,
    };
  },
};
</script>

<style lang="scss">
.banner-alert {
  position: absolute;
  top: 11rem;
  right: 1rem;
  z-index: 11;
  border-radius: 4px;
  display: none;

  @include breakpoint($desktop-sml) {
    padding: 1rem 1.5rem;
    width: 32rem;
    display: block;
  }

  &--amber {
    background-color: #f59e0b;
    right: 34rem;

    .banner-alert__dropdown,
    .banner-alert__alert-link {
      background-color: #d97706;
    }

    .banner-alert__body {
      &::before {
        background-color: #fbbf24;
      }
    }
  }

  &--red {
    background-color: #ef4444;

    .banner-alert__dropdown,
    .banner-alert__alert-link {
      background-color: #dc2626;
    }

    .banner-alert__body {
      &::before {
        background-color: #f87171;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__header-left {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    .exclamation-triangle {
      width: 26px;
      height: 26px;
      color: #ffffff;
    }

    .exclamation-circle {
      width: 26px;
      height: 26px;
      color: #ffffff;
    }
  }

  &__count {
    font-size: 1.5rem;
    font-weight: 600;
    color: #ffffff;
  }

  &__count-type {
    text-transform: capitalize;
  }

  &__dropdown {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    color: #ffffff;

    span {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  &__content {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &::before {
      content: "";
      width: 100%;
      height: 1px;
      background-color: #fff;
      display: block;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.25rem 0;
    color: #ffffff;
    font-weight: 600;

    &::before {
      content: "";
      width: 100%;
      height: 1px;
      display: block;
    }

    &:first-of-type {
      &::before {
        display: none;
      }
    }
  }

  &__alert-text {
    margin-top: 1rem;
    margin: 0;
  }

  &__alert-link-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__alert-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: 600;
    text-decoration: none;

    .chevron-down {
      transform: rotate(-90deg);
    }
  }
}
</style>
