<template>
  <div
    class="hero-slider__slide"
    :style="heroSlideStyle"
  >
    <div class="inner center">
      <div class="hero__content">
        <h1 v-if="heroData.fields.title" class="hero__heading">
          {{ heroData.fields.title }}
        </h1>
        <p v-if="heroData.fields.subtitle">{{ heroData.fields.subtitle }}</p>
        <a
          v-if="heroData.fields.link"
          class="btn btn--primary btn--hero"
          :target="heroData.fields.link.target ? '_blank' : null"
          :href="heroData.fields.link.url"
          >{{ heroData.fields.link.name }}</a
        >
      </div>
    </div>
    <div class="hero__gradient"></div>
  </div>
</template>

<script>
export default {
  name: "HeroPlain",
  props: ["heroData"],
  data() {
    return {
      applyThinHero: this.heroData.fields.applyThinHero,
    };
  },
  computed: {
    heroSlideStyle() {
      return {
        height: this.applyThinHero ? '40rem' : '65rem',
        backgroundImage: 'url(' + this.heroData.fields.image.fields.umbracoFile.src + ')',
      };
    },
  },
};
</script>
