<template>
  <div
    class="hero-slider__slide hero-car"
    :style="{
      backgroundImage:
        'url(' + heroData.fields.image.fields.umbracoFile.src + ')',
    }"
  >
    <div class="inner">
      <div class="hero__content">
        <!-- commented out the Airport Services button for now as it might be needed at a later date -->
        <!-- <ul class="breadcrumb">
          <li>
            <a href="#" tabindex="0"><span>Airport services</span></a>
            <meta itemprop="position" content="1" />
          </li>
        </ul> -->
        <h1 class="hero__heading">{{ heroData.fields.title }}</h1>
        <div class="hero-form hero-form--booking hero-form--mobile">
          <button type="submit" class="btn btn--primary btn--hero" tabindex="0">
            Book Car Hire
          </button>
        </div>
        <div class="hero-form hero-form--booking hero-form--desktop">
          <div class="grid">
            <div class="grid__half">
              <label for="hc_pickUpLocation">Pick-up location</label>
              <Select2
                id="hc_pickUpLocation"
                name="hc_pickUpLocation"
                :options="hc_airports"
                :settings="{
                  placeholder: 'Please select',
                }"
                v-model="hc_pickUpLocation"
              />
            </div>
          </div>
          <div class="grid">
            <div class="grid__half">
              <div class="grid">
                <div class="grid__half">
                  <label for="hc_pickUpDate">Pick-up date</label>
                  <DatePicker
                    v-model="hc_pickUpDate"
                    :min-date="new Date()"
                    :masks="{ input: this.displayFormat }"
                    class="datepicker"
                    :popover="{ visibility: 'focus' }"
                    is-required
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        :value="inputValue || this.labels.labelDate"
                        v-on="inputEvents"
                      />
                    </template>
                  </DatePicker>
                </div>
                <div class="grid__half">
                  <div class="grid grid--inner">
                    <div class="grid__half">
                      <label for="hc_pickUpHour"
                        ><span class="hiddenFromView">Pick-up</span> time
                        <span class="hiddenFromView">hours</span></label
                      >
                      <Select2
                        id="hc_pickUpHour"
                        name="hc_pickUpHour"
                        :options="[
                          '00',
                          '01',
                          '02',
                          '03',
                          '04',
                          '05',
                          '06',
                          '07',
                          '08',
                          '09',
                          '10',
                          '11',
                          '12',
                          '13',
                          '14',
                          '15',
                          '16',
                          '17',
                          '18',
                          '19',
                          '20',
                          '21',
                          '22',
                          '23',
                        ]"
                        v-model="hc_pickUpHour"
                      />
                    </div>
                    <label for="hc_pickUpMinute"
                      ><span class="hiddenFromView"
                        >Pick-up time minutes</span
                      ></label
                    >
                    <div class="grid__half">
                      <Select2
                        id="hc_pickUpMinute"
                        name="hc_pickUpMinute"
                        :options="['00', '15', '30', '45']"
                        v-model="hc_pickUpMinute"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid__half">
              <div class="grid">
                <div class="grid__half">
                  <label for="hc_dropOffDate">Drop-off date</label>
                  <DatePicker
                    id="hc_dropOffDate"
                    name="hc_dropOffDate"
                    v-model="hc_dropOffDate"
                    :min-date="new Date()"
                    :masks="{ input: this.displayFormat }"
                    class="datepicker"
                    :popover="{ visibility: 'focus' }"
                    is-required
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        :value="inputValue || this.labels.labelDate"
                        v-on="inputEvents"
                      />
                    </template>
                  </DatePicker>
                </div>
                <div class="grid__half">
                  <div class="grid grid--inner">
                    <div class="grid__half">
                      <label for="dropOffHour"
                        ><span class="hiddenFromView">Drop-off</span> time
                        <span class="hiddenFromView">hours</span></label
                      >
                      <Select2
                        id="hc_dropOffHour"
                        name="hc_dropOffHour"
                        :options="[
                          '00',
                          '01',
                          '02',
                          '03',
                          '04',
                          '05',
                          '06',
                          '07',
                          '08',
                          '09',
                          '10',
                          '11',
                          '12',
                          '13',
                          '14',
                          '15',
                          '16',
                          '17',
                          '18',
                          '19',
                          '20',
                          '21',
                          '22',
                          '23',
                        ]"
                        v-model="hc_dropOffHour"
                      />
                    </div>
                    <label for="dropOffMinute"
                      ><span class="hiddenFromView"
                        >Drop-off time minutes</span
                      ></label
                    >
                    <div class="grid__half">
                      <Select2
                        id="hc_dropOffMinute"
                        name="hc_dropOffMinute"
                        :options="['00', '15', '30', '45']"
                        v-model="hc_dropOffMinute"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            @click="hc_getURLPOST()"
            type="submit"
            class="btn btn--primary btn--hero"
            tabindex="0"
          >
            Book Car Hire
          </button>
        </div>
      </div>
    </div>
    <div class="hero__gradient"></div>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import axios from "axios";
import dayjs from "dayjs";
export default {
  name: "HeroCar",
  props: ["heroData", "componentData"],
  inject: ["publicSiteSettings", "labels"],
  data() {
    return {
      hc_pickUpLocation: "",
      hc_pickUpDate: "",
      hc_pickUpHour: "00",
      hc_pickUpMinute: "00",
      hc_dropOffDate: null,
      hc_dropOffHour: "00",
      hc_dropOffMinute: "00",
      hc_airports: [],
      hc_vehiclesURL: "",
      hc_displayFormat:
        this.publicSiteSettings.fields.globalLocale === "en-US"
          ? "DD-MM-YYYY"
          : "DD-MM-YYYY",
      hc_timeFormat:
        this.publicSiteSettings.fields.globalLocale === "en-US"
          ? "H:mm:ss"
          : "H:mm:ss",
      hc_displayFormatReverse: "YYYY-MM-DD",
    };
  },
  computed: {},
  components: {
    Select2,
    DatePicker,
  },
  mounted() {
    this.hc_loadLocations();
  },
  watch: {
    selectedLanguage: "loadLocations",
  },
  methods: {
    hc_loadLocations() {
      this.webpage = this.$route.params.slug
        ? "/" + this.$route.params.slug.replace(/\/?$/, "/")
        : "/";

      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/AirportsApi/GetAirportsLite?url=/airports/&iataCode=${process.env.VUE_APP_IATACODE}&cultureCode=${this.selectedLanguage}`;

      axios
        .get(apiUrl)
        .then((response) => {
          this.hc_airports = response.data.airports;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    hc_formatDateTime(hc_incomingDate, hc_returnType) {
      let hc_returnFormat = this.hc_displayFormat + " " + this.hc_timeFormat;
      switch (hc_returnType) {
        case "date":
          hc_returnFormat = this.hc_displayFormat;
          break;
        case "time":
          hc_returnFormat = this.hc_timeFormat;
          break;
        case "dateReverse":
          hc_returnFormat = this.hc_displayFormatReverse;
          break;
      }
      return dayjs(hc_incomingDate).format(hc_returnFormat);
    },
    hc_getURLPOST() {
      const hc_pickUpTimeString =
        this.hc_pickUpHour + ":" + this.hc_pickUpMinute + ":00";
      const hc_dropOffTimeString =
        this.hc_dropOffHour + ":" + this.hc_dropOffMinute + ":00";
      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL + `/api/CarHireApi/GetCarsAvailable`;
      axios({
        method: "post",
        url: apiUrl,
        headers: {},
        data: {
          pickUpLocation: this.hc_pickUpLocation,
          dropOffLocation: this.hc_pickUpLocation,
          pickUpDate: this.hc_formatDateTime(this.hc_pickUpDate, "date"),
          pickUpTime: hc_pickUpTimeString,
          returnDate: this.hc_formatDateTime(this.hc_dropOffDate, "date"),
          returnTime: hc_dropOffTimeString,
          iataCode: process.env.VUE_APP_IATACODE,
          cultureCode: this.selectedLanguage,
        },
      })
        .then((response) => {
          this.hc_vehiclesURL = response.data;
          window.open(this.hc_vehiclesURL);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
.hero {
  &__gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-image: linear-gradient(
      -107deg,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 0.13) 93%
    );
  }
}

.hero__content .btn--primary {
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }
}
</style>
