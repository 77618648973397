<template>
    <h1>This is step two</h1>
    <button @click.prevent="wizardStore.previousStep()">Previous</button>
    <button @click.prevent="wizardStore.nextStep()">Next</button>
</template>

<script>
import { useBookingWizardStore } from '../../stores/bookingwizardstore';

export default {
    name:'MockStep2',
    setup(){
        const wizardStore = useBookingWizardStore();
        return {
            wizardStore
        }
    }
}
</script>