<template>
    <ul>
        <li v-for="(step,index) in steps" :key="index" >
            <a @click.prevent="goToStep(step, index)"  v-bind:class="index == wizardStore.step ? 'active' : ''">{{ step.data.fields.stepLabel }}</a>
        </li>
    </ul>
</template>
<style>
    .active {
        font-weight: bold;
        font-size: larger;
    }
</style>

<script>
import { useBookingWizardStore } from "../../stores/bookingwizardstore";

export default {
    name:"ProgressBar",
    props:["steps"],
    setup(props) {
        const wizardStore = useBookingWizardStore();
        return {
            wizardStore, 
        }
    },
    methods:{
        goToStep(step, stepIndex){
            const wizardStore = useBookingWizardStore();

            var stepCondtions = step.data.fields.stepCondition;
            var isValid = false;

            for(var i = 0; i < stepCondtions.length; i++){
                var condition = stepCondtions[i];

                switch(condition){
                    case "If Basket Has A Parking Product":
                        isValid = this.checkDoesBasketContainParkingProduct();
                        break;
                    case "If There Are Extras":
                        isValid = this.checkForExtras();
                        break;
                    case "If Basket Has Products":
                        isValid = wizardStore.basket != null && wizardStore.basket.products.length > 0 ? true : false;
                        break;
                    default:
                        isValid = true;
                        break;
                }
            }

            if(isValid){
                wizardStore.setStep(stepIndex);
            }

        },
        checkDoesBasketContainParkingProduct(){
            const wizardStore = useBookingWizardStore();
            var basket = wizardStore.basket;

            if(basket != null){
                var query = basket.products.filter(x => x.productType = 0);

                return query.length > 0;
            }

            return false;
        },
        checkForExtras(){
            //TODO: - Awaiting HNGR-1119
            return false;
        }
    }
}
</script>