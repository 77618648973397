<template>
    <h1>This is step one</h1>
    <VariantInput name="Adult" productId="12"  price="20.00" currency="£" maxQty="10" minQty="1" value="2" />
    <button @click.prevent="wizardStore.nextStep()">Next</button>
</template>

<script>
import { useBookingWizardStore } from '../../stores/bookingwizardstore';
import VariantInput from './VariantInput.vue';


export default {
    name:'MockStep1',
    components:{
        VariantInput
    },
    setup(){
        const wizardStore = useBookingWizardStore();
        return {
            wizardStore
        }
    }
}
</script>