<template>
  <div
    class="signup-modal"
    :class="modalActive !== null && 'signup-modal--active'"
  >
    <div class="signup-modal__outer">
      <div class="signup-modal__inner">
        <button class="signup-modal__close" @click="toggleModal(null)">
          <SvgIcon name="close" class="icon icon-close-flight" />
        </button>
        <header class="signup-modal__header" v-if="modalHeaderText">
          <h2>{{ modalHeaderText }}</h2>
          <p>{{ settings.emailModalTitle }}</p>
        </header>
        <div name="forms.watch" class="signup-modal__form form" novalidate="">
          <div>
            <br />
            <input
              type="email"
              placeholder="Enter your email address"
              v-model="email"
              required="required"
            />
          </div>
          <div class="form__row checkbox signup-modal__checkbox">
            <div>
              <input
                id="watch-flight-email-opt-in"
                type="checkbox"
                v-model="optIn"
              />
              <label
                for="watch-flight-email-opt-in"
                v-html="settings.emailModalOptin"
              >
              </label>
            </div>
          </div>
          <div class="signup-modal__form-footer">
            <input
              type="submit"
              class="btn btn--primary"
              value="Receive updates"
              @click="submitFormInFlightStatusBar"
            />
          </div>
          <div v-if="formSubmitted">
            <p>
              {{ submitMessage }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="flight-wrapper">
    <ul class="flight__tabs">
      <li
        v-for="(flight, index) in model.flights"
        :key="index"
        :class="{ selected: index === selectedIndex }"
        @click="selectTab(index)"
      >
        {{ flight.scheduledDate }}
      </li>
    </ul>

    <article
      v-for="(flight, index) in model.flights"
      :key="index"
      class="flight__content"
      :class="{ selected: index === selectedIndex, active: infoBarActive }"
    >
      <div class="flight__destination">
        <span class="flight__label">
          {{
            flight.location.direction == 0 ? "Arriving From" : "Destination"
          }}</span
        >
        <h3>{{ flight.location.name }}</h3>

        <div class="stopovers" v-if="flight.intermediateSteps.length > 0">
          Via
          <span
            v-for="(step, stepIndex) in flight.intermediateSteps"
            :key="stepIndex"
            >{{ step }}</span
          >
        </div>
        <div
          class="flight__weather"
          v-if="
            flight.location.weather != null &&
            flight.location.weather.description.length > 0
          "
        >
          <div
            :style="{
              backgroundImage: 'url(' + flight.location.weather.iconUrl + ')',
            }"
          ></div>
          <span>{{ flight.location.weather.description }}</span>
        </div>
      </div>
      <div class="flight__date">
        <ul>
          <li>{{ flight.scheduledTime }}</li>
          <li>{{ flight.scheduledDate }}</li>
        </ul>
        <ul>
          <li>{{ flight.flightNumber }}</li>
          <li>{{ flight.airlineName }}</li>
        </ul>
      </div>
      <div class="flight__status">
        <span class="flight__label">Status</span>
        <div class="flight__status__notification">
          <h4 :class="getStatus(flight.statusMessage)">
            {{ flight.statusMessage }}
          </h4>
          <span class="flight__status__notification-bell">
            <SvgIcon name="bell" class="icon icon-bell" @click="showInfoBar" />
          </span>
        </div>
      </div>
      <div class="flight__info-bar" :class="{ active: infoBarActive }">
        <div
          v-if="publicSiteSettings._value.fields.enableFlightUpdates"
          class="flight__btn"
        >
          <!-- <a class="flight__social-btn"
            ><SvgIcon name="facebook" class="icon"
          /></a> -->
          <!-- <a class="flight__social-btn"
            ><SvgIcon name="twitter" class="icon"
          /></a> -->
          <a class="flight__social-btn" @click.prevent="toggleModal(index)">
            <SvgIcon name="email" class="icon" />
          </a>
        </div>
      </div>
      <span class="flight__label flight__updated"
        >Updated at {{ model.flightInfo.lastUpdatedTime }}</span
      >
    </article>
  </section>
</template>
<script>
import SvgIcon from "@/components/Icon";
import axios from "axios";

export default {
  name: "FlightStatusComponent",
  inject: ["publicSiteSettings", "labels"],
  emits: ["modal-open"],
  components: {
    SvgIcon,
  },
  data() {
    return {
      selectedIndex: 0,
      model: {},
      tabs: [],
      infoBarActive: false,
      modalActive: null,
      email: "",
      optIn: false,
      settings: this.publicSiteSettings.fields,
      submitMessage: this.publicSiteSettings.fields.emailModalSuccess,
      formSubmitted: false,
    };
  },
  methods: {
    getStatus(status) {
      if (status.includes("Go to Gate") || status.includes("Check")) {
        return "success";
      }
      if (status.includes("Cancelled")) {
        return "warning";
      }
      return null;
    },
    selectTab(i) {
      this.selectedIndex = i;
    },
    showInfoBar() {
      this.infoBarActive = !this.infoBarActive;
    },
    toggleModal(index) {
      this.modalActive = index;

      this.modalActive === null
        ? document.body.classList.remove("has-overlay")
        : document.body.classList.add("has-overlay");

      this.modalActive === null
        ? (document.querySelector(".header__hamburger").style.zIndex = 1003)
        : (document.querySelector(".header__hamburger").style.zIndex = 0);
    },
    submitFormInFlightStatusBar() {
      const payload = {
        emailAddress: this.email,
        flightNumber: this.model.flightNumber,
        MarketingOptIn: this.optIn,
        flightDateTime: this.model.flightDate,
        iataCode: process.env.VUE_APP_IATACODE,
      };

      axios
        .post(
          process.env.VUE_APP_REMOTEBASEURL +
            "/api/LiveFlightsApi/RegisterFlightUpdateEmails",
          payload
        )
        .then((response) => {
          this.formSubmitted = true;
          this.submitMessage = this.settings.emailModalSuccess;
          console.log("Registration successful", response.data);
        })
        .catch((error) => {
          this.formSubmitted = true;
          this.submitMessage =
            "Sorry, there was a problem submitting your email, please try again later.";
          console.error("Registration failed", error.response.data);
        });
    },
  },
  mounted() {
    console.log(
      "enableFlightUpdates:",
      this.publicSiteSettings._value.fields.enableFlightUpdates
    );
    axios
      .get(
        process.env.VUE_APP_REMOTEBASEURL +
          "/api/flightstatusapi/GetFlightDetails?flightCode=" +
          this.$route.query["flightCode"] +
          "&iataCode=" +
          process.env.VUE_APP_IATACODE +
          "&flightDateTime=" +
          this.$route.query["flightDateTime"] +
          "&flightDirection=" +
          this.$route.query["flightDirection"]
      )
      .then((response) => {
        this.model = response.data;
      });
  },
  computed: {
    modalHeaderText: function () {
      if (this.modalActive === null) return "";
      return `Watch flight ${
        this.model.flights[this.modalActive].flightNumber
      } ${
        this.model.flights[this.modalActive].location.direction == "0"
          ? "from"
          : "to"
      } ${this.model.flights[this.modalActive].location.name}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.flight-wrapper {
  background: $white;
  max-width: 114rem;
}
.success {
  color: $alert-success !important;
}
.warning {
  color: $alert-warning !important;
}

.flight__tabs {
  display: flex;
  flex-direction: row;

  & li {
    flex-grow: 1;
    font-size: 1.2rem;
    color: $skin-colour-primary-bright;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    border-left: 1px solid $skin-colour-primary;
    border-top: 1px solid $skin-colour-primary;

    height: 3rem;
    line-height: 3rem;
    padding: 0 0.5rem;

    &.selected {
      color: $white;
      background: $skin-colour-primary-bright;
      border-color: $skin-colour-primary-bright !important;
    }

    &:last-child {
      border-right: 1px solid $skin-colour-primary;
    }
  }
}

.flight__content {
  border: 1px solid $skin-colour-primary;
  display: none;
  width: 100%;
  padding: 0 1.5rem;
  position: relative;
  overflow: hidden;
  grid-template-rows: 1fr 6.5rem 6.5rem;
  grid-template-columns: repeat(2, 1fr);
  position: relative;

  &.active:after {
    background: rgba($color: $white, $alpha: 0.9);
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  @include breakpoint($desktop-sml) {
    padding: 2rem 0;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(4, 1fr);
  }

  &.selected {
    display: grid;
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0;
    border-bottom: 1px solid #e0e4f0;

    &:nth-last-child(3) {
      border-bottom: 0;
    }

    @include breakpoint($tablet) {
      &:nth-last-child(2) {
        border-bottom: 0;
      }
      &:nth-last-child(3) {
        border-bottom: 1px solid #e0e4f0;
      }
    }

    @include breakpoint($desktop-sml) {
      padding: 0 1.5rem;
      border-left: 1px solid #e0e4f0;
      border-bottom: 0;

      &:first-child,
      &:nth-last-child(2),
      &:nth-last-child(1) {
        border-left: 0;
      }

      &:nth-last-child(3) {
        border-bottom: 0;
      }
    }
  }
}

.flight__destination {
  width: 100%;
  grid-area: 1 / 1 / 2 / 3;

  @include breakpoint($desktop-sml) {
    grid-area: 1 / 1 / 2 / 2;
  }

  h3 {
    font-size: 2.6rem;
    margin-bottom: 0 !important;
    color: $skin-colour-primary;
  }

  .stopovers {
    font-weight: 400;
    font-size: 1.6rem;
  }
}

.flight__label {
  color: $skin-colour-primary-bright;
  display: block;
  font-size: 1.2rem;
  font-weight: 400;
}
.flight__updated {
  padding: 1rem;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 150px;
  background: $white;
}

.flight__weather {
  margin-top: 0.5rem;

  & span {
    margin-left: 0.5rem;
    color: #5d5d5d;
    font-size: 1.2rem;
    font-weight: 400;
  }

  & div {
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 25px;
    height: 25px;
  }
}

.flight__date {
  width: 100%;
  flex-direction: row !important;
  justify-content: flex-start !important;
  grid-area: 2 / 1 / 2 / 3;

  @include breakpoint($tablet) {
    grid-area: 2 / 1 / 3 / 2;
  }

  @include breakpoint($desktop-sml) {
    grid-area: 1 / 2 / 2 / 3;
  }

  & ul {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }

    & li {
      font-size: 1.2rem;
      font-weight: 700;
      line-height: 2rem;
    }
  }
}

.flight__status {
  width: 100%;
  grid-area: 3 / 1 / 3/ 3;

  @include breakpoint($tablet) {
    grid-area: 2 / 2 / 3 / 3;
  }

  @include breakpoint($desktop-sml) {
    grid-area: 1 / 3 / 2 / 4;
  }

  h4 {
    margin-bottom: 0 !important;
    color: $skin-colour-primary;
  }
}

.flight__status__notification {
  display: flex;
  align-items: center;
  gap: 1rem;

  .flight__status__notification-bell {
    display: none;
  }
}

.flight__info-bar {
  width: 100%;
  grid-area: 3 / 1 / 3/ 3;
  z-index: 2;
  display: flex;

  @include breakpoint($tablet) {
    grid-area: 3 / 1 / 4 / 3;
  }

  @include breakpoint($desktop-sml) {
    grid-area: 1 / 4 / 2 / 5;
  }

  &.active {
    display: flex !important;
  }

  .flight__btn {
    display: flex;
    justify-content: flex-end;
    position: relative;
    column-gap: 15px;

    @include breakpoint($desktop-sml) {
      justify-content: flex-end;
      margin-right: 2rem;
    }

    & a {
      position: relative;
      border-radius: 50%;
      border: 1px solid $skin-colour-primary-bright;
      width: 30px;
      height: 30px;
      font-size: 1.4rem;

      & .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-size: 1.7rem;
        color: $skin-colour-primary-bright;
      }
    }
  }
}

.icon-bell,
.icon-close-flight {
  cursor: pointer;
  display: block;
  position: absolute;
  right: 10px;
  color: $skin-colour-primary-bright;

  @include breakpoint($tablet) {
    display: none;
  }
}

.signup-modal {
  position: relative;
  opacity: 0;
  pointer-events: none;

  &--active {
    opacity: 1;
    pointer-events: auto;
  }

  &__outer {
    position: fixed;
    inset: 0;
    z-index: 9998 !important;
    background-color: rgba(76, 73, 69, 0.85);
    display: grid;
  }

  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 95%;
    max-width: 66rem;
    overflow: visible;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 2px;
    text-align: center;
    padding: 4rem 1.5rem 2rem;

    @include breakpoint($tablet) {
      padding: 4rem 5rem;
    }
  }

  &__close {
    position: absolute;
    top: 2rem;
    right: 1.5rem;
    display: block;
    width: 1.8rem;
    height: 1.8rem;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__checkbox {
    margin-top: 1rem;
  }

  &__form-footer {
    display: flex;
    justify-content: flex-start;
  }
}
.flight__social-btn {
  display: grid;
  place-items: center;
  cursor: pointer;
}
</style>
