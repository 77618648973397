<template>
  <span class="loader-spinner"></span>
</template>
<script>
export default {
  name: "LoaderSpinner",
};
</script>
<style lang="scss" scoped>
.loader-spinner {
  width: 48px;
  height: 48px;
  border: 5px solid #fafafa;
  border-bottom-color: $skin-colour-secondary;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
