<template>
  <div
    class="
      timeline-panel timeline-panel--filled
      timeline-parking timeline-marker
    "
  >
    <h3>
      <span>
        <img
          v-if="componentData.fields.icon != null"
          :src="componentData.fields.icon.fields.umbracoFile.src"
        />
      </span>
      {{ componentData.fields.title }}
    </h3>
    <div>{{ componentData.fields.extraInfo }}</div>
    <div class="timeline-parking-form">
      <div class="grid">
        <div class="grid__half">
          <label for="entryDate">{{ labels.labelEntryDate }}</label>
          <DatePicker
            v-model="entryDate"
            :min-date="new Date()"
            class="datepicker"
            :masks="{ input: displayFormat }"
            :popover="{ visibility: 'focus' }"
            is-required
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input :value="inputValue || `Date`" v-on="inputEvents" />
            </template>
          </DatePicker>
        </div>
        <div class="grid__half">
          <label for="entryTime">&nbsp;</label>
          <Select2
            :options="optionsTime"
            v-model="entryTime"
            :settings="{ placeholder: labels.labelTime }"
          />
        </div>
      </div>
      <div class="grid">
        <div class="grid__half">
          <label for="exitDate">{{ labels.labelExitDate }}</label>
          <DatePicker
            v-model="exitDate"
            :min-date="new Date()"
            class="datepicker"
            :masks="{ input: displayFormat }"
            :popover="{ visibility: 'focus' }"
            is-required
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input :value="inputValue || `Date`" v-on="inputEvents" />
            </template>
          </DatePicker>
        </div>
        <div class="grid__half">
          <label for="exitTime">&nbsp;</label>
          <Select2
            :options="optionsTime"
            v-model="exitTime"
            :settings="{ placeholder: 'Time' }"
          />
        </div>
        <div
          :style="{
            display: !componentData.fields.suppressTerminalElementInBookingForm
              ? 'block'
              : 'none',
          }"
          class="airline cf"
        >
          <label for="airline">{{ labels.labelAirline }}</label>
          <option
            v-if="!componentData.fields.suppressTerminalElementInBookingForm"
            id="airline"
            value="-1"
            style="display: none"
            :selected="
              componentData.fields.suppressTerminalElementInBookingForm
            "
          ></option>

          <Select2
            v-if="!componentData.fields.suppressTerminalElementInBookingForm"
            :options="optionsAirline"
            :settings="{
              placeholder: labels.labelSelectAirline,
            }"
            v-model="airlineTerminal"
            @select="updateTerminal"
          />
        </div>
      </div>
    </div>

    <div class="button">
      <form
        method="post"
        novalidate="novalidate"
        data-role="parking-form"
        v-on:submit="submitForm"
      >
        <input type="hidden" value="1" name="parkingDetailsSubmitted" />
        <input type="hidden" value="1" name="progressToNextStep" />
        <input type="hidden" name="terminal" v-model="terminal" />
        <input type="hidden" value="" name="promocodes" />
        <input type="hidden" name="entryDate" v-model="entryDateNormalised" />
        <input type="hidden" name="entryTime" v-model="entryTime" />
        <input type="hidden" name="exitDate" v-model="exitDateNormalised" />
        <input type="hidden" name="exitTime" v-model="exitTime" />
        <input
          type="submit"
          class="btn btn--primary"
          :value="labels.buttonBook"
        />
      </form>
    </div>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import parkingForm from "../../mixins/parkingForm";

export default {
  name: "TimelineParkingPanel",
  props: ["componentData"],
  components: {
    Select2,
    DatePicker,
  },
  mixins: [parkingForm],
  computed: {
    optionsTime() {
      return this.buildTimeslots();
    },
    optionsAirline() {
      return this.buildOptionsAirline();
    },
  },
  data() {
    return {
      promoCode: this.componentData.fields.internalPromoCode ?? "",
    };
  },
};
</script>

<style lang="scss" scoped>
.timeline-parking {
  .timeline-parking-form {
    margin-top: 30px;

    .grid {
      margin-bottom: 30px;
    }

    .grid__half {
      width: 100%;
      margin-right: 0;

      @include breakpoint($tablet) {
        width: 50%;
      }
    }

    label {
      font-size: 1.8rem;
      color: $white;
    }
  }

  .btn--primary {
    margin-top: 20px;
  }

  .btn--secondary {
    margin-top: 15px;
  }

  @include breakpoint($tablet) {
    .btn--primary {
      margin-right: 30px;
    }
  }
}
</style>
