<template>
    <div class="wrapper">
      <div class="inner">
        <div v-if="loadHtmlComponent"
          id="html-component"
        ></div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "ScriptAndHTMLComponent",
    props: ["componentData"],
    data() {
      return {
        loadHtmlComponent: false,
        headScript: this.componentData.fields.headScriptField,
        htmlScript: this.componentData.fields.hTMLField,
        bodyScript: this.componentData.fields.bodyScriptField
      };
    },
    computed: {},
    mounted() {
      this.loadHeadScriptFunction();
      this.loadBodyScriptFunction();
      this.loadBodyHTMLScriptFunction();
    },
    methods: {
        loadHeadScriptFunction() {
      if (
        this.headScript != undefined &&
        this.headScript != ""
      ) {
        this.$nextTick(() => {
          const scriptElement = document.createElement("script");
          scriptElement.id = "headScript";
          scriptElement.type = "text/javascript";
          scriptElement.async = true;
          scriptElement.src = this.headScript;
          document.head.appendChild(scriptElement);
        });
      }
    },
        loadBodyScriptFunction() {
      if (
        this.bodyScript != undefined &&
        this.bodyScript != ""
      ) {
        this.$nextTick(() => {
          const scriptElement = document.createElement("script");
          scriptElement.id = "bodyScript";
          scriptElement.type = "text/javascript";
          scriptElement.async = true;
          scriptElement.src = this.bodyScript;
          document.body.appendChild(scriptElement);
        });
      }
    },
    loadBodyHTMLScriptFunction() {
  if (
    this.htmlScript != undefined &&
    this.htmlScript != ""
  ) {
    this.loadHtmlComponent = true;
    this.$nextTick(() => {
        const htmlString = this.htmlScript;
        const targetDiv = document.getElementById("html-component");

        if (targetDiv) {
        targetDiv.innerHTML += htmlString;
        } else {
        console.error("Div with id 'html-component' not found.");
        }
    });
  }
}
},
};
  </script>
  
  <style lang="scss" scoped>
  .wrapper {
    background: #f4f7ff;
    border-radius: 12px;
    padding: 3rem 2rem;
    margin: 4rem 0;
  }
  </style>
  