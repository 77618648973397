<template>
  <div class="basket" v-if="basket != null">
    <header class="basket__header">
      <h3>Summary</h3>
    </header>
    <div
      class="basket__body"
      v-for="(item, index) in wizardStore.basket.products"
      :key="index"
    >
      <div class="basket__block">
        <div class="basket__block-header">
          <h4>{{ item.productName }}</h4>
        </div>
        <div class="basket__items">
          <div class="basket__item">
            <span class="basket__item-label">Entry:</span>
            <span class="basket__item-value">{{ displayEntryDate }}</span>
          </div>
          <div class="basket__item">
            <span class="basket__item-label">Exit:</span>
            <span class="basket__item-value">{{ displayExitDate }}</span>
          </div>
          <template v-for="(variant, i) in item.variants" :key="i">
            <div class="basket__item">
              <span class="basket__item-label">£{{ variant.unitPrice }}</span>
              <span class="basket__item-value"
                >{{ variant.quantity }} x {{ variant.variantName }}</span
              >
            </div>
          </template>
        </div>
      </div>
      <template v-if="wizardStore.basket.totalDiscount > 0">
        <div class="basket__block">
          <div class="basket__items basket__items--narrow">
            <div class="basket__item">
              <span class="basket__item-label">Subtotal:</span>
              <span class="basket__item-value">£20.99</span>
            </div>
            <div class="basket__item">
              <span class="basket__item-label basket__item-label--active">{{
                promoCodeApplied
              }}</span>
              <span class="basket__item-value basket__item-value--active"
                >£{{ wizardStore.basket.totalDiscountDisplayValue }}</span
              >
            </div>
          </div>
        </div>
      </template>
      <div class="basket__block">
        <div class="basket__items">
          <div class="basket__item">
            <span class="basket__item-label">Total:</span>
            <span class="basket__item-value"
              >£{{ wizardStore.basket.totalDisplayValue }}</span
            >
          </div>
        </div>
      </div>
      <div class="basket__footer">
        <input
          class="btn btn--primary"
          v-if="wizardStore.step > 0"
          type="button"
          value="Continue"
          @click.prevent="continueToCheckout()"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.basket {
  margin-top: 40px;

  @include breakpoint($desktop) {
    margin-top: 88px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    border: 1px solid #f3f4f6;
    box-shadow:
      0 3px 3px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 2rem;
    background-color: #fafafa;
    gap: 3rem;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  &__block-header {
    padding-bottom: 0.25rem;
    border-bottom: 1px solid #e9e9e9;

    h4 {
      margin-bottom: 0.25rem;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    &--narrow {
      gap: 0.5rem;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__item-label {
    &--active {
      color: oklch(0.723 0.219 149.579);
    }

    font-size: 1.6rem;

    @include breakpoint($tablet) {
      font-size: 1.4rem;
    }
  }

  &__item-value {
    font-size: 1.4rem;

    @include breakpoint($tablet) {
      font-size: 1.2rem;
    }

    &--active {
      color: oklch(0.723 0.219 149.579);
      text-decoration: line-through;
    }
  }

  &__footer {
    padding-top: 1rem;
    border-top: 1px solid #e9e9e9;

    .btn {
      width: 100%;
    }
  }
}
</style>

<script>
import { useBookingWizardStore } from "../../stores/bookingwizardstore";
import dayjs from "dayjs";

export default {
  name: "BasketSummary",
  props: ["steps"],
  setup() {
    const wizardStore = useBookingWizardStore();
    return {
      wizardStore,
    };
  },
  data() {
    /*   var basket = {
            "id": "d833cf1d-f3ec-4f69-84ba-b111c41b1ae5",
            "outboundFlightCode": "",
            "inboundFlightCode": "",
            "reasonForTravel": null,
            "entryDate": "2025-02-28T16:00:00+00:00",
            "exitDate": "2025-03-01T16:00:00+00:00",
            "products": [
                {
                    "id": 530,
                    "parentId": 0,
                    "productName": "Mid Stay",
                    "carParkName": "Mid Stay",
                    "logoURI": "https://static.aeroparker.com/products/logos/19/6509-7953.png",
                    "productType": 0,
                    "variants": [
                        {
                            "quantity": 1,
                            "originalValue": 125.99,
                            "discount": 0,
                            "promoCode": null,
                            "averagePricePerDay": 6.63,
                            "variantName": "Mid Stay",
                            "variantId": null,
                            "unitPrice": 125.99
                        }
                    ],
                    "subtotalPrice": 125.99,
                    "totalDiscount": 0,
                    "total": 125.99,
                    "averagePricePerDay": 0
                },
                {
                    "id": 71,
                    "parentId": 0,
                    "productName": "Priority Security",
                    "carParkName": null,
                    "logoURI": "https://static.aeroparker.com/shopProducts/logos/19/71-0575.png",
                    "productType": 1,
                    "variants": [
                        {
                            "quantity": 1,
                            "originalValue": 6.99,
                            "discount": 0,
                            "promoCode": null,
                            "averagePricePerDay": 0,
                            "variantName": "Simple",
                            "variantId": null,
                            "unitPrice": 6.99
                        }
                    ],
                    "subtotalPrice": 6.99,
                    "totalDiscount": 0,
                    "total": 6.99,
                    "averagePricePerDay": 0
                },
                {
                    "id": 77,
                    "parentId": 0,
                    "productName": "The Lomond Lounge",
                    "carParkName": null,
                    "logoURI": "https://static.aeroparker.com/shopProducts/logos/19/77-4023.png",
                    "productType": 1,
                    "variants": [
                        {
                            "quantity": 2,
                            "originalValue": 45,
                            "discount": 0,
                            "promoCode": null,
                            "averagePricePerDay": 0,
                            "variantName": "Adult",
                            "variantId": null,
                            "unitPrice": 45
                        }
                    ],
                    "subtotalPrice": 90,
                    "totalDiscount": 0,
                    "total": 90,
                    "averagePricePerDay": 0
                }
            ],
            "promotions": null,
            "parkingAvailabilityWindowId": null,
            "subTotalPrice": 222.98,
            "totalDiscount": 0,
            "total": 222.98,
            "promoCodeApplied": null,
            "subTotalPriceDisplayValue": "222.98",
            "totalDiscountDisplayValue": "0.00",
            "totalDisplayValue": "222.98",
            "isInbound": false,
            "isEachWay": false,
            "cmd": null,
            "numberOfAdults": 0,
            "numberOfChildren": 0
          } */

    var basket = this.wizardStore.basket;
    return { basket };
  },
  methods: {
    continueToCheckout() {
      this.wizardStore.nextStep();
    },
  },
  computed: {
    displayEntryDate() {
      return dayjs(this.basket.entryDate).format("dddd, MMMM D, YYYY h:mm A");
    },
    displayExitDate() {
      return dayjs(this.basket.exitDate).format("dddd, MMMM D, YYYY h:mm A");
    },
  },
};
</script>
