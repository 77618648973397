<template>
  <section id="pinned-parking" class="parking-form-widget cf">
    <header>
      <span class="pinned-parking__icon"></span>
      <h2 class="pinned-parking__title">
        {{ this.labels.labelPersistentParkingFormIntro }}
      </h2>
    </header>
    <div class="form form--inline" data-role="parking-form-wrapper">
      <fieldset>
        <div class="grid">
          <div class="grid__sixth">
            <label for="entry">{{ this.labels.labelEntryDate }}</label>

            <DatePicker
              v-model="entryDate"
              :min-date="new Date()"
              :masks="{ input: this.displayFormat }"
              class="datepicker"
              :popover="{ visibility: 'focus' }"
              @change="this.updateTeaserPrice"
              is-required
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  :value="inputValue"
                  v-on="inputEvents"
                  :readonly="this.hideKeyboardOnMobile()"
                />
              </template>
            </DatePicker>
          </div>
          <div class="grid__sixth">
            <label for="entryTime"
              ><span class="hiddenFromView">Pick-up</span>
            </label>
            <Select2
              class="selectbox-time"
              :options="optionsTime"
              :settings="{ placeholder: this.labels.labelTime }"
              v-model="entryTime"
              @select="updateTeaserPrice"
            />
          </div>
          <div class="grid__sixth">
            <label for="exit">{{ this.labels.labelExitDate }}</label>

            <DatePicker
              v-model="exitDate"
              :min-date="entryDate"
              :masks="{ input: this.displayFormat }"
              class="datepicker"
              :popover="{ visibility: 'focus' }"
              @change="this.updateTeaserPrice"
              is-required
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  :value="inputValue"
                  v-on="inputEvents"
                  :readonly="this.hideKeyboardOnMobile()"
                />
              </template>
            </DatePicker>
          </div>
          <div class="grid__sixth">
            <label for="exitTime"
              ><span class="hiddenFromView">Pick-up</span>
            </label>
            <Select2
              class="selectbox-time"
              :options="optionsTime"
              :settings="{ placeholder: this.labels.labelTime }"
              v-model="exitTime"
              @select="updateTeaserPrice"
            />
          </div>
          <div
            :style="{
              display:
                !stickyBookingParkingWidgetSettings.suppressTerminalElement
                  ? ''
                  : 'none',
            }"
            class="grid__sixth"
          >
            <label for="terminal">{{ this.labels.labelAirline }}</label>
            <!-- <option
              v-if="stickyBookingParkingWidgetSettings.suppressTerminalElement"
              id="terminal"
              style="display: none"
              value="-1"
            ></option> -->

            <Select2
              v-if="!stickyBookingParkingWidgetSettings.suppressTerminalElement"
              :options="optionsAirline"
              :settings="{ placeholder: this.labels.labelSelectAirline }"
              v-model="airlineTerminal"
              @select="updateTerminal"
            />
          </div>
          <div class="grid__rest">
            <form
              target="_blank"
              method="post"
              novalidate="novalidate"
              data-role="parking-form"
              v-on:submit="submitForm"
            >
              <input type="hidden" value="1" name="parkingDetailsSubmitted" />
              <input type="hidden" value="1" name="progressToNextStep" />
              <input type="hidden" name="terminal" v-model="terminal" />
              <input type="hidden" value="" name="promocodes" />
              <input
                type="hidden"
                name="entryDate"
                v-model="entryDateNormalised"
              />
              <input type="hidden" name="entryTime" v-model="entryTime" />
              <input
                type="hidden"
                name="exitDate"
                v-model="exitDateNormalised"
              />
              <input type="hidden" name="exitTime" v-model="exitTime" />
              <input
                type="submit"
                class="btn btn--primary"
                :value="this.labels.buttonBook"
              />
            </form>
          </div>
          <div class="grid__full">
            <label
              class="price-from"
              v-if="
                !stickyBookingParkingWidgetSettings.suppressPriceElementInBookingForm &&
                teaserPrice
              "
            >
              {{
                this.labels.labelPriceTeaser.replace(
                  "{0}",
                  this.currency + this.teaserPrice
                )
              }}
            </label>
          </div>
        </div>
      </fieldset>
    </div>
  </section>
</template>

<script>
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import parkingForm from "../../mixins/parkingForm";

export default {
  name: "StickyBookingParkingWidget",
  props: {
    stickyBookingParkingWidgetSettings: Object,
    selectedLanguage: String,
    widgetBookingPageInfo: Object,
  },
  components: {
    Select2,
    DatePicker,
  },
  data() {
    return {
      entryDate: new Date(),
      exitDate: new Date(),
    };
  },
  mixins: [parkingForm],
  computed: {
    optionsTime() {
      return this.buildTimeslots();
    },
    optionsAirline() {
      return this.buildOptionsAirline();
    },
  },
  created() {
    window.addEventListener("scroll", (e) => {
      this.handleScroll(
        e,
        this.widgetBookingPageInfo.fields.showMultipleFormBarOverParkingBar
      );
    });
  },
  unmounted() {
    window.removeEventListener("scroll", (e) => {
      this.handleScroll(
        e,
        this.widgetBookingPageInfo.fields.showMultipleFormBarOverParkingBar
      );
    });
  },
  methods: {
    hideKeyboardOnMobile() {
      if (document.body.classList.contains("has-overlay")) {
        return "readonly";
      }
      return false;
    },
    handleScroll: (e, PBBool) => {
      let scrollPos = window.scrollY;
      let stickyBar = document.getElementById("pinned-parking");

      if (scrollPos > 600 && !PBBool) {
        stickyBar.classList.add("pinned-parking--fixed");
      } else {
        stickyBar.classList.remove("pinned-parking--fixed");
      }
    },
  },
};
</script>
