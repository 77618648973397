<template>
   <section class="panel-padding">
    <div class="mailing-list">
      <div class="inner">
        <header class="mailing-list__intro">
          <h2 v-if="componentData.fields.header" class="mailing-list__heading">
            {{ componentData.fields.header }}
          </h2>
          <div v-if="componentData.fields.introField" v-html="componentData.fields.introField"></div>
        </header>
        <form method="post" v-on:submit="submitForm">
          <div class="grid">
            <div class="grid__half">
              <label for="name"> 
                {{ componentData.fields.nameField }}
            </label>
              <input
                id="name"
                type="text"
                :placeholder="componentData.fields.nameField"
                v-model="nameField"
              />
            </div>
            <div class="grid__half">
              <label for="email">  
                {{ componentData.fields.emailField }}
            </label>
              <input
                id="emailField"
                type="email"
                :placeholder="componentData.fields.emailField"
                v-model="emailField"
              />
            </div>

            <div v-if="componentData.fields.phoneField" class="grid__half">
              <label for="phone">  
                {{ componentData.fields.phoneField }}
            </label>
              <input
                id="phone"
                type="tel"
                :placeholder="componentData.fields.phoneField"
                v-model="phoneField"
              />
            </div>

            <div v-if="componentData.fields.messageField" class="grid__half">
              <label for="message">  
                {{ componentData.fields.messageField }}
            </label>
              <textarea
                id="message"
                type="textarea"
                :placeholder="componentData.fields.messageField"
                v-model="messageField"
              ></textarea>
            </div>

            <div v-if="componentData.fields.extraInputField" class="grid__half">
              <label for="extraInputField">  
                {{ componentData.fields.extraInputField }}
            </label>
              <input
                id="extraInputField"
                type="text"
                :placeholder="componentData.fields.extraInputField"
                v-model="extraInputField"
              />
            </div>

            <div v-if="componentData.fields.formOptionsField" class="grid__half">
                <label for="options">
                    {{ componentData.fields.formOptionsField }}
                </label>
            <Select2
                  :options="componentData.fields.formOptions"
                  :settings="{
                    placeholder: componentData.fields.formOptions[0],
                  }"
                  v-model="option"
                >
                </Select2>
            </div>

            <div class="grid__full checkbox">
              <input
                id="mailingListTermsConditions"
                type="checkbox"
                v-model="mailingListTermsConditions"
              />
              <label
                for="mailingListTermsConditions"
                v-html="componentData.fields.termsAndConditionsText"
              ></label>
            </div>
            <div class="grid__full">
              <input
                type="submit"
                class="btn btn--primary"
                :value="componentData.fields.buttonText"
              />
            </div>
          </div>

          <!-- Error Messages -->
          <div
            v-if="errorVisible"
            :class="{
              'mailing-list__success': errorClass,
              'mailing-list__error': !errorClass,
            }"
          >
            <ul>
              <li v-for="(error, index) in errorMessages" :key="index">
                {{ error }}
              </li>
            </ul>
          </div>
        </form>
      </div>
    </div>
  </section>
  </template>
  
  <script>
  import Select2 from "vue3-select2-component";
  import parkingForm from "../../mixins/parkingForm";
  import axios from "axios";
  
  export default {
    name: "StaticContactUsForm",
    props: ["componentData"],
    mixins: [parkingForm],
    data() {
      return {
        isValidResponse: false,
        errorVisible: false,
        errorClass: false,
        nameField: "",
        emailField: "",
        phoneField: "",
        messageField: "",
        extraInputField: "",
        option: this.componentData.fields.formOptions[0] !== null ? this.componentData.fields.formOptions[0] : "",
        toEmailAddress: this.componentData.fields.toEmailAddress,
        mailingListTermsConditions: false,
        cResponse: this.calculatedResponse,
      };
    },
    components: {
    Select2
  },
    methods: {
    submitForm(e) {
      e.preventDefault();
      console.log("submitting form");

      // Reset error states
      this.errorMessages = []; // Change from a single string to an array
      this.errorVisible = false;
      this.errorClass = false;

      // Validate Name
      if (!this.nameField.trim()) {
        this.errorMessages.push("Name is required.");
      }

      // Validate Email
      if (!this.emailField.trim()) {
        this.errorMessages.push("Email is required.");
      } else if (!this.validateEmail(this.emailField)) {
        this.errorMessages.push("Please enter a valid email address.");
      }

      // Validate Terms & Conditions Checkbox with cleaned and properly quoted label
      if (!this.mailingListTermsConditions) {
        const rawTermsLabel =
          this.componentData?.fields?.termsErrorMessage || "Terms and Conditions";
        const termsLabel = `"${this.stripHtmlTags(rawTermsLabel).trim()}"`; // Trim any extra whitespace
        this.errorMessages.push(`${termsLabel} checkbox is required.`);
      }

      // If any errors exist, show them and stop form submission
      if (this.errorMessages.length > 0) {
        this.errorVisible = true;
        this.errorClass = false;
        return;
      }

      axios
        .post(
          process.env.VUE_APP_REMOTEBASEURL + "/api/FormApi/AddToStaticMailingList",
          {
            name: this.nameField,
            email: this.emailField,
            phone: this.phoneField,
            message: this.messageField,
            extraInputField: this.extraInputField,
            option: this.option,
            componentId: this.componentData.system.id,
            toEmailAddress: this.toEmailAddress,
            iataCode: process.env.VUE_APP_IATACODE,
          }
        )
        .then((response) => {
          if (response.data) {
            if (this.componentData.fields.thanksPageRedirect !== undefined) {
              window.location.href = this.componentData.fields.thanksPageRedirect.url;
            }
            this.errorMessages = ["Your email has successfully been sent!"];
            this.errorVisible = true;
            this.errorClass = true;
          } else {
            this.errorMessages = [
              "Sorry, your email has not been sent, please try again later.",
            ];
            this.errorVisible = true;
            this.errorClass = false;
          }
        });
    },
    stripHtmlTags(input) {
      return input ? input.replace(/<\/?[^>]+(>|$)/g, "").trim() : "";
    },
    validateEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
  },
    mounted() {
      this.isValidResponse = true;
    },
  };
  </script>
  