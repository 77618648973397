<template>
  <div class="inner">
    <div class="hero__content">
      <!-- commented out the Airport Services button for now as it might be needed at a later date -->
      <!-- <ul class="breadcrumb">
        <li>
          <a href="#" tabindex="0"><span>Airport services</span></a>
          <meta itemprop="position" content="1" />
        </li>
      </ul> -->
      <h1 class="hero__heading">{{ heroData.fields.loungeHeroHeading }}</h1>
      <div class="hero-form hero-form--booking">
        <div class="grid">
          <div class="grid__half">
            <div class="grid">
              <div class="grid__half">
                <label for="entryDate">{{ this.labels.labelEntryDate }}</label>
                <DatePicker
                  v-model="entryDate"
                  :min-date="new Date()"
                  :masks="{ input: this.displayFormat }"
                  class="datepicker"
                  :popover="{ visibility: 'focus' }"
                  is-required
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      :value="inputValue || this.labels.labelDate"
                      v-on="inputEvents"
                    />
                  </template>
                </DatePicker>
              </div>
              <div class="grid__half">
                <label for="entryTime">{{ this.labels.labelTime }}</label>
                <Select2
                  data-field="hidden-arrival-hour"
                  class="selectbox-time"
                  :options="optionsTime"
                  :settings="{
                    placeholder: this.labels.labelTime,
                  }"
                  v-model="entryTime"
                />
              </div>
            </div>
          </div>
          <div class="grid__half">
            <div class="grid">
              <div class="grid__half">
                <label for="loungeAdults">
                  {{ this.labels.labelAdults }}
                </label>
                <Select2
                  name="Lounge_numAdults"
                  data-field="hidden-adult"
                  class="selectbox"
                  :options="['1', '2', '3', '4', '5']"
                  :settings="{
                    placeholder: '0',
                  }"
                  v-model="loungeAdults"
                />
              </div>
              <div class="grid__half">
                <label for="loungeChild">
                  {{ this.labels.labelChildren }}
                </label>
                <Select2
                  name="Lounge_numChildren"
                  data-field="hidden-children"
                  class="selectbox"
                  :options="['0', '1', '2', '3', '4', '5']"
                  :settings="{
                    placeholder: '0',
                  }"
                  v-model="loungeChild"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="grid__full"></div>
        </div>
        <form
          @submit="submitLoungeForm"
          :action="reactivePublicSiteSettings.fields.pBBLoungeFormLink"
          method="post"
          target="_blank"
          class="ng-pristine ng-valid"
          novalidate="novalidate"
        >
          <button
            v-if="!heroData.fields.loungeHideButton"
            type="submit"
            class="btn btn--primary btn--hero"
            tabindex="0"
          >
            {{ this.labels.buttonBook }}
          </button>
          <!-- Primary fields for the Lounge banner -->
          <input type="hidden" value="1" name="submitted" />
          <input
            type="hidden"
            name="arrivalDate"
            v-model="entryDateNormalised"
          />
          <input
            type="hidden"
            name="EscapeLounge_numAdults"
            v-model="loungeAdults"
          />
          <input
            type="hidden"
            name="EscapeLounge_numChildren"
            v-model="loungeChild"
          />
          <input
            type="hidden"
            name="AirportVisitDetails_arrival_hour"
            v-model="entryTime"
          />
        </form>
        <div
          v-if="!isValidResponse"
          class="booking-module__error"
          data-message="time-slot-not-available"
        >
          Sorry, no availability for your chosen timeslot.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import parkingForm from "../../mixins/parkingForm";
import dayjs from "dayjs";
import axios from "axios";

export default {
  name: "LoungeForm",
  props: ["heroData"],
  mixins: [parkingForm],
  data() {
    return {
      isValidResponse: false,
      entryDate: "",
      entryTime: "",
      loungeAdults: "1",
      loungeChild: "1",
    };
  },
  computed: {
    optionsTime() {
      return this.buildLoungeTimeSlots();
    },
  },
  methods: {
    submitLoungeForm(event) {
      event.preventDefault();
      axios
        .get(
          process.env.VUE_APP_REMOTEBASEURL +
            "/api/AeroparkerBookingApi/GetAncillaryAvailability?arrivalDateTime=" +
            dayjs(this.entryDate).format("YYYY-MM-DD HH:mm") +
            "&iataCode=" +
            process.env.VUE_APP_IATACODE
        )
        .then((response) => {
          if (response.data) {
            this.isValidResponse = response.data;
            event.target.submit();
          } else {
            this.isValidResponse = response.data;
          }
        });
    },
  },
  components: {
    Select2,
    DatePicker,
  },
  mounted() {
    this.isValidResponse = true;
  },
};
</script>
