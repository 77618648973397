<template>
  <div class="booking-details">
    <div class="booking-details__header">
      <h2>{{ componentData.fields.displayName }}</h2>
    </div>
    <div class="booking-details__form">
      <div class="booking-details__form-group" v-if="guiOptions.editOnInit">
        <label>{{ componentData.fields.entryLabel }}:</label>
        <div class="booking-details__form-input-group">
          <DatePicker
            v-model="entryDate"
            :min-date="new Date()"
            class="datepicker"
            :popover="{ visibility: 'focus' }"
            is-required
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input :value="inputValue" v-on="inputEvents" />
            </template>
          </DatePicker>
          <Select2
            class="booking-details__time selectbox-time"
            :options="buildTimeslots"
            :settings="{ placeholder: 'Time' }"
            v-model="entryTime"
          />
        </div>
      </div>
      <div class="booking-details__form-group" v-if="guiOptions.editOnInit">
        <label>{{ componentData.fields.exitLabel }}:</label>
        <div class="booking-details__form-input-group">
          <DatePicker
            v-model="exitDate"
            :min-date="new Date()"
            class="datepicker"
            :popover="{ visibility: 'focus' }"
            is-required
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input :value="inputValue" v-on="inputEvents" />
            </template>
          </DatePicker>
          <Select2
            class="booking-details__time selectbox-time"
            :options="buildTimeslots"
            :settings="{ placeholder: 'Time' }"
            v-model="exitTime"
          />
        </div>
      </div>
      <div>
        <label>{{ componentData.fields.sortByLabel }}</label>
        <Select2
          class="selectbox-time"
          :options="sortOptions"
          :settings="{ placeholder: 'Time' }"
          v-model="sortBy"
        />
      </div>
      <div class="booking-details__submit" v-if="guiOptions.editOnInit">
        <input
          type="button"
          :value="
            guiOptions.editOnInit
              ? componentData.fields.cTAButtonText
              : 'Update'
          "
          @click.prevent="Search()"
          class="btn btn--primary"
        />
      </div>
    </div>
    <div class="booking-details__footer">
      <div
        class="booking-details__message booking-details__message--error"
        v-if="!guiOptions.validForm"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
            clip-rule="evenodd"
          />
        </svg>
        <span>{{ componentData.fields.errorMessage }}</span>
      </div>
      <div
        class="booking-details__message booking-details__message--cancellation"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
            clip-rule="evenodd"
          />
        </svg>
        <span>
          {{ componentData.fields.cancellationMessage }}
        </span>
      </div>
    </div>

    <div class="loader-wrapper" v-if="loading">
      <loader-spinner />
    </div>

    <!-- Parking Products -->
    <div class="parking-details" v-if="!loading">
      <template v-for="(product, index) in parkingProducts" :key="index">
        <div class="parking-details__product-wrapper">
          <h3
            :style="{ color: product.featuredTextColour }"
            v-if="product.featuredText != null && product.featuredText != ''"
            class="parking-details__featured-header"
          >
            {{ product.featuredText }}
          </h3>
          <div class="parking-details__product">
            <div class="parking-details__header">
              <h4 class="parking-details__title">
                {{
                  product.displayName != null
                    ? product.displayName
                    : product.title
                }}
              </h4>
              <a href="#" class="parking-details__more-info">
                <span>More Info</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </a>
            </div>
            <div class="parking-details__features">
              <div class="parking-details__feature-wrapper">
                <template v-if="product.transferDetails.type == 0">
                  <img src="../../assets/images/icon-bus.png" alt="Bus" />
                  <span>{{ product.transferDetails.description }}</span>
                  {{ product.transferDetails.time }}
                </template>
                <template v-if="product.transferDetails.type == 1">
                  <img src="../../assets/images/icon-walk.png" alt="Walking" />
                  <span>{{ product.transferDetails.description }}</span>
                  {{ product.transferDetails.time }}
                </template>
                <template
                  v-if="
                    product.transferDetails.type != 0 ||
                    product.transferDetails.type != 1
                  "
                >
                  <span>{{ product.transferDetails.description }}</span>
                  {{ product.transferDetails.time }}
                </template>
              </div>
              <template v-if="product.features != null">
                <template
                  v-for="(feature, index) in product.features"
                  :key="index"
                >
                  <div class="parking-details__feature-wrapper">
                    <p class="parking-details__feature">
                      {{ feature }}
                    </p>
                  </div>
                </template>
              </template>
            </div>

            <div class="parking-details__footer">
              <div class="parking-details__footer-inner">
                <div
                  class="parking-details__urgency"
                  v-if="
                    product.urgencyMessage != null ||
                    product.urgencyMessage != ''
                  "
                >
                  <span>{{ product.urgencyMessage }}</span>
                </div>

                <p class="parking-details__price">
                  &pound;{{ product.variants[0].unitPrice }}
                </p>

                <div
                  class="parking-details__discounts"
                  v-if="product.variants[0].discount > 0"
                >
                  <span class="parking-details__discount-price"
                    >&pound;{{ product.variants[0].orignalValue }}</span
                  >
                  <span class="parking-details__discount-code">{{
                    product.variants[0].promoCode
                  }}</span>
                </div>

                <input
                  type="button"
                  value="Buy Now"
                  @click.prevent="AddToBasket(product)"
                  class="parking-details__submit btn btn--primary"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.booking-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      margin: 0;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    @include breakpoint($tablet) {
      flex-direction: row;
    }
  }

  &__form-group {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
  }

  &__form-input-group {
    display: flex;
  }

  &__time {
    flex-grow: 1;
  }

  &__submit {
    @include breakpoint($tablet) {
      align-self: flex-end;
    }

    input {
      width: 100%;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
  }

  &__message {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    border-radius: 6px;
    padding: 10px 15px;
    font-weight: 500;

    @include breakpoint($tablet) {
      align-self: flex-start;
    }

    &--cancellation {
      background-color: #e6f6e4;

      svg {
        color: #4caf50;
      }
    }

    &--error {
      background-color: #f6e6e6;
      color: #9f3a38;
    }

    svg {
      width: 20px !important;
      height: 20px;
    }
  }
}

.parking-details {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.parking-details__featured-header {
  margin-bottom: 0.75rem;
}

.parking-details__product {
  border: 1px solid #f3f4f6;
  box-shadow:
    0 3px 3px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  overflow: hidden;

  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 170px 1fr 160px;
  }
}

.parking-details__header {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid #cdcdcd;
  background: linear-gradient(to bottom right, #0ea5e9, #2563eb);

  @media screen and (min-width: 1024px) {
    border-bottom: none;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
  }
}

.parking-details__title {
  margin: 0;
  text-align: center;
  font-size: 2rem;
  color: #ffffff;

  @media screen and (min-width: 1024px) {
    text-align: left;
  }
}

.parking-details__more-info {
  display: flex;
  align-items: center;
  color: #ffffff;
}

.parking-details__more-info span {
  font-size: 14px;
}

.parking-details__more-info svg {
  width: 16px;
  height: 16px;
  color: #ffffff;
}

.parking-details__features {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 1fr);

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    align-items: center;
    // border-left: 1px solid #cdcdcd;
    border-right: 1px solid #f3f4f6;
    margin: 0 1rem;
  }
}

.parking-details__feature-wrapper {
  border-top: 1px solid #f3f4f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;

  @media screen and (min-width: 1024px) {
    border-left: 1px solid #f3f4f6;
    border-top: none;
    padding: 0 1rem;
    align-self: stretch;
  }

  img {
    width: 70px;
  }
}

.parking-details__feature-wrapper:first-child {
  border-top: none;

  @media screen and (min-width: 1024px) {
    border-left: none;
  }
}

.parking-details__feature {
  margin: 0;
  padding: 1rem;
  text-align: center;

  @media screen and (min-width: 1024px) {
    font-size: 14px;
    text-align: center;
  }
}

.parking-details__feature:first-child {
  @media screen and (min-width: 1024px) {
    border-left: none;
  }
}

.parking-details__footer {
  border-top: 1px solid #f3f4f6;
  display: grid;
  place-items: center;

  @media screen and (min-width: 1024px) {
    display: grid;
    place-items: center;
    border-top: none;
  }
}

.parking-details__footer-inner {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @media screen and (min-width: 1024px) {
    transform: translateX(-0.5rem);
    padding: 0;
  }
}

.parking-details__urgency {
  span {
    color: #9f3a38;
    font-size: 14px;
    text-align: center;
  }
}

.parking-details__price {
  margin: 0;
  font-weight: bold;
}

.parking-details__discounts {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.parking-details__discount-price {
  text-decoration: line-through;
}

.parking-details__discount-code {
}

.parking-details__submit {
  @media screen and (min-width: 1024px) {
    font-size: 14px;
    padding: 8px 30px;
    align-self: center;
    flex-shrink: 0;
    min-width: auto;
  }
}
</style>

<script>
import dayjs from "dayjs";
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import axios from "axios";

import { useBookingWizardStore } from "../../stores/bookingwizardstore";
import LoaderSpinner from "../Loading/LoaderSpinner.vue";

let guiOptions = {
  editOnInit: true,
  validForm: true,
};

export default {
  name: "BookingDetails",
  props: ["componentData"],
  components: {
    DatePicker,
    Select2,
    LoaderSpinner,
  },
  setup() {
    const wizardStore = useBookingWizardStore();
    return {
      wizardStore,
    };
  },
  data() {
    return {
      entryDate: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000),
      exitDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      entryTime: "",
      exitTime: "",
      sortOptions: [
        { id: "Price", text: "Price (low-high)" },
        { id: "PriceDec", text: "Price (high-low)" },
        { id: "Time", text: "Time" },
      ],
      guiOptions,
      parkingProducts: [],
      sortBy: "Price",
      loading: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      var queryEntryDate = this.$route.query["entryDateTime"];
      var queryExitDate = this.$route.query["exitDateTime"];

      if (queryEntryDate !== undefined && queryExitDate !== undefined) {
        this.entryDate = queryEntryDate;
        this.exitDate = queryExitDate;
        guiOptions.editOnInit = false;
      }

      var basket = await this.GetBasket();

      if (basket != null) {
        this.entryDate = basket.entryDate;
        this.exitDate = basket.exitDate;
      }
    },

    AddToBasket(product) {
      const wizardStore = useBookingWizardStore();

      var entryDateTime = this.buildEntryDateTime();
      var exitDateTime = this.buildExitDateTime();

      wizardStore.setEntryDate(entryDateTime);
      wizardStore.setExitDate(exitDateTime);

      var request = {
        arrivalDateTime: entryDateTime.toISOString(),
        returnDateTime: exitDateTime.toISOString(),
        type: "Parking",
        iataCode: process.env.VUE_APP_IATACODE,
        parkingAvailabilityWindowKey: null,
        basket: wizardStore.basket,
        productId: product.aeroParkerProductId,
        variantId: null,
        variantQuantity: 1,
        variantName: null,
      };

      axios
        .post(
          process.env.VUE_APP_REMOTEBASEURL +
            "/api/bookingapi/AddOrUpdateBasket",
          request,
        )
        .then(function (response) {
          wizardStore.addBasket(response.data.basket);
          wizardStore.nextStep();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    async GetBasket() {
      const wizardStore = useBookingWizardStore();
      return wizardStore.basket;
    },
    buildEntryDateTime() {
      try {
        const [hours, minutes] = this.entryTime.split(":");
        return new Date(
          this.entryDate.getFullYear(),
          this.entryDate.getMonth(),
          this.entryDate.getDate(),
          hours,
          minutes,
        );
      } catch {
        return null;
      }
    },
    buildExitDateTime() {
      try {
        const [hours, minutes] = this.exitTime.split(":");
        return new Date(
          this.exitDate.getFullYear(),
          this.exitDate.getMonth(),
          this.exitDate.getDate(),
          hours,
          minutes,
        );
      } catch {
        return null;
      }
    },

    Search() {
      var result = null;

      if (this.validateForm()) {
        this.guiOptions.validForm = true;
        const wizardStore = useBookingWizardStore();

        var entryDateTime = this.buildEntryDateTime();
        var exitDateTime = this.buildExitDateTime();

        var request = {
          arrivalDateTime: entryDateTime.toISOString(),
          returnDateTime: exitDateTime.toISOString(),
          type: "Parking",
          iataCode: process.env.VUE_APP_IATACODE,
          parkingAvailabilityWindowKey:
            wizardStore.parkingAvailabilityWindowKey,
          sortBy: this.sortBy,
          basket: wizardStore.basket,
        };

        this.loading = true;

        axios
          .post(
            process.env.VUE_APP_REMOTEBASEURL +
              "/api/bookingapi/GetDisplayProductsFromSearch",
            request,
          )
          .then((response) => {
            if (response != null) {
              this.loading = false;
              wizardStore.setParkingAvailabilityWindowKey(
                response.data.parkingAvailabilityWindowKey,
              );
              this.parkingProducts = response.data.products;
            }
          });
      } else {
        this.guiOptions.validForm = false;
      }

      return result;
    },

    validateForm() {
      try {
        if (
          this.entryTime != null &&
          this.entryTime !== "" &&
          this.exitTime != null &&
          this.exitTime !== ""
        ) {
          var entryDateTime = this.buildEntryDateTime();
          var exitDateTime = this.buildExitDateTime();

          if (exitDateTime <= entryDateTime) {
            this.guiOptions.validForm = false;
            return false;
          }
        } else {
          return false;
        }
      } catch {
        return false;
      }

      return true;
    },
  },
  computed: {
    buildTimeslots() {
      // Build Timeslots
      var slotStart = "00:00";
      var slotStartHour = parseInt(slotStart.split(":")[0]) || 0;
      var slotStartMins = parseInt(slotStart.split(":")[1]) || 0;
      var slotEnd = "24:01";
      var slotEndHour = parseInt(slotEnd.split(":")[0]) || 23;
      var slotEndMins = parseInt(slotEnd.split(":")[1]) || 59;
      var slotLength = 60;
      var startOfDayAdjustment = true;
      var formatString = "HH:mm";
      var timeslots = [];
      for (let hours = slotStartHour; hours <= slotEndHour; hours++) {
        for (
          let mins = hours == slotStartHour ? slotStartMins : 0;
          mins < (hours == slotEndHour ? slotEndMins : 60);
          mins += slotLength
        ) {
          let friendlyStartTime =
            String("00" + hours).slice(-2) +
            ":" +
            String("00" + mins).slice(-2);
          if (friendlyStartTime == "00:00" || friendlyStartTime == "24:00") {
            friendlyStartTime = startOfDayAdjustment ? "00:01" : "23:59";
            startOfDayAdjustment = false;
          }
          timeslots.push(friendlyStartTime);
        }
      }
      var timeslotsFriendly = timeslots.map(function (time) {
        return {
          id: time,
          text: dayjs("1970-01-01 " + time).format(formatString),
        };
      });
      return timeslotsFriendly;
    },
  },
};
</script>
