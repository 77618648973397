<template>
    <h1>This is step three</h1>
    <button @click.prevent="wizardStore.previousStep()">Previous</button>
</template>

<script>
import { useBookingWizardStore } from '../../stores/bookingwizardstore';

export default {
    name:'MockStep3',
    setup(){
        const wizardStore = useBookingWizardStore();
        return {
            wizardStore
        }
    }
}
</script>