<template>
  <section class="panel-padding">
    <div class="mailing-list">
      <div class="inner">
        <header class="mailing-list__intro">
          <h2 class="mailing-list__heading">
            {{ componentData.fields.title }}
          </h2>
          <div v-html="componentData.fields.intro"></div>
        </header>
        <form method="post" v-on:submit="submitForm">
          <div class="grid">
            <div class="grid__half">
              <label for="mailingListName">Name</label>
              <input
                id="mailingListName"
                type="text"
                :placeholder="this.labels.labelName"
                v-model="mailingListName"
              />
            </div>
            <div class="grid__half">
              <label for="mailingListEmail">Email</label>
              <input
                id="mailingListEmail"
                type="email"
                :placeholder="this.labels.labelEmail"
                v-model="mailingListEmail"
              />
            </div>

            <!-- Additional Fields -->
            <template
              v-for="(item, index) in componentData.fields.additionalFields"
              :key="index"
            >
              <div
                class="grid__full checkbox"
                v-if="item.fields.type.toLowerCase() === 'checkbox'"
              >
                <input
                  :id="'field_' + index"
                  name="mailingListAdditionalField"
                  v-model="mailingListAdditionalField[index]"
                  type="checkbox"
                />
                <label
                  :for="'field_' + index"
                  v-html="item.fields.label"
                ></label>
              </div>
              <div class="grid__half" v-else>
                <label :for="'field_' + index">{{ item.fields.label }}</label>
                <input
                  :id="'field_' + index"
                  v-model="mailingListAdditionalField[index]"
                  :type="item.fields.type.toLowerCase()"
                />
              </div>
            </template>

            <div class="grid__full checkbox">
              <input
                id="mailingListTermsConditions"
                type="checkbox"
                v-model="mailingListTermsConditions"
              />
              <label
                for="mailingListTermsConditions"
                v-html="componentData.fields.terms"
              ></label>
            </div>
            <div class="grid__full">
              <input
                type="submit"
                class="btn btn--primary"
                :value="componentData.fields.buttonText"
              />
            </div>
          </div>

          <!-- Error Messages -->
          <div
            v-if="errorVisible"
            :class="{
              'mailing-list__success': errorClass,
              'mailing-list__error': !errorClass,
            }"
          >
            <ul>
              <li v-for="(error, index) in errorMessages" :key="index">
                {{ error }}
              </li>
            </ul>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import responseCalculator from "../../mixins/responseCalculator";

export default {
  name: "MailingListBlock",
  props: ["componentData"],
  inject: ["labels"],
  mixins: [responseCalculator],
  data() {
    return {
      errorMessage: "",
      errorVisible: false,
      errorClass: false,
      mailingListName: "",
      mailingListEmail: "",
      mailingListAdditionalField: [],
      mailingListTermsConditions: false,
    };
  },
  methods: {
    submitForm(e) {
      e.preventDefault();
      console.log("submitting form");

      // Reset error states
      this.errorMessages = []; // Change from a single string to an array
      this.errorVisible = false;
      this.errorClass = false;

      // Validate Name
      if (!this.mailingListName.trim()) {
        this.errorMessages.push("Name is required.");
      }

      // Validate Email
      if (!this.mailingListEmail.trim()) {
        this.errorMessages.push("Email is required.");
      } else if (!this.validateEmail(this.mailingListEmail)) {
        this.errorMessages.push("Please enter a valid email address.");
      }

      // Validate Additional Required Fields
      this.componentData.fields.additionalFields.forEach((item, index) => {
        if (item.fields.mandatory && !this.mailingListAdditionalField[index]) {
          this.errorMessages.push(
            item.fields.placeholder + "is required." ||
              "This field is required."
          );
        }
      });

      // Validate Terms & Conditions Checkbox with cleaned and properly quoted label
      if (!this.mailingListTermsConditions) {
        const rawTermsLabel =
          this.componentData?.fields?.terms || "Terms and Conditions";
        const termsLabel = `"${this.stripHtmlTags(rawTermsLabel).trim()}"`; // Trim any extra whitespace
        this.errorMessages.push(`${termsLabel} checkbox is required.`);
      }

      // If any errors exist, show them and stop form submission
      if (this.errorMessages.length > 0) {
        this.errorVisible = true;
        this.errorClass = false;
        return;
      }

      axios
        .post(
          process.env.VUE_APP_REMOTEBASEURL + "/api/FormApi/AddToMailingList",
          {
            email: this.mailingListEmail,
            name: this.mailingListName,
            additionalFields: this.mailingListAdditionalField,
            componentId: this.componentData.system.id,
            cResponse: this.calculatedResponse,
            iataCode: process.env.VUE_APP_IATACODE,
          }
        )
        .then((response) => {
          if (response.data) {
            if (this.componentData.fields.thanksPage !== undefined) {
              window.location.href = this.componentData.fields.thanksPage.url;
            }
            this.errorMessages = ["Your email has successfully been sent!"];
            this.errorVisible = true;
            this.errorClass = true;
          } else {
            this.errorMessages = [
              "Sorry, your email has not been sent, please try again later.",
            ];
            this.errorVisible = true;
            this.errorClass = false;
          }
        });
    },
    stripHtmlTags(input) {
      return input ? input.replace(/<\/?[^>]+(>|$)/g, "").trim() : "";
    },
    validateEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
  },
};
</script>

<style lang="scss">
.mailing-list {
  color: $promo-module-color;
  background-color: $skin-colour-primary;
  padding: 4rem 0;

  &__heading {
    color: $promo-module-color !important;
    font-size: 3.2rem;

    @include breakpoint($desktop-sml) {
      font-size: 4.6rem;
    }
  }

  &__error {
    background: #f1cece;
    border: 1px solid #ff1101;
    color: #ff1101;
    display: block;
    font-size: 14px;
    padding: 1rem 2rem;
    text-align: center;

    @include breakpoint($tablet-sml) {
      display: inline-block;
    }
  }

  &__success {
    background: #55be4b;
    border: 1px solid #62be7e;
    color: #1d4225c4;
    display: block;
    font-size: 14px;
    padding: 1rem 2rem;
    text-align: center;

    @include breakpoint($tablet-sml) {
      display: inline-block;
    }
  }

  label {
    color: $promo-module-color;
  }

  input {
    margin-bottom: 2rem;
  }

  .checkbox {
    margin-bottom: 2rem;
  }
}
</style>
