<template>
  <header
    class="header"
    role="banner"
    :class="
      ((this.publicSiteSettings?.fields?.airportName || ' ')
        .replace(' ', '')
        .toLowerCase(),
      navItems > 6 && 'header--wide')
    "
  >
    <div class="inner">
      <div class="logo-wrapper">
        <a
          :href="publicSiteSettings.fields.headerLogoLink.url"
          :title="publicSiteSettings.fields.headerLogoLink.name"
          :target="publicSiteSettings.fields.headerLogoLink.target"
          class="header__logo"
          itemprop="url"
          v-if="
            publicSiteSettings.fields.headerLogoLink &&
            publicSiteSettings.fields.headerLogo
          "
        >
          <img
            :src="publicSiteSettings.fields.headerLogo.fields.umbracoFile.src"
            :alt="publicSiteSettings.fields.headerLogo.fields.alt"
          />
        </a>
      </div>
      <div
        class="header__logo"
        v-if="
          !publicSiteSettings.fields.headerLogoLink &&
          publicSiteSettings.fields.headerLogo
        "
      >
        <img
          :src="publicSiteSettings.fields.headerLogo.fields.umbracoFile.src"
          :alt="publicSiteSettings.fields.headerLogo.fields.alt"
        />
      </div>

      <div
        class="navigation-wrapper"
        :class="navItems > 6 && 'navigation-wrapper--wide'"
      >
        <div v-if="amberAlerts.length > 0 || redAlerts.length > 0">
          <MixedAlertModal :amberAlerts="amberAlerts" :redAlerts="redAlerts" />
        </div>

        <button v-on:click.stop="mobileMenu" class="header__hamburger">
          <div>
            <SvgIcon name="close" class="close-icon" />
            <SvgIcon name="menu" class="burger-icon" />
          </div>
        </button>

        <!--
          THIS IS KEPT IN SO SEARCH CAN BE ADDED IN LATER
          <div class="header__search">
						<form action="" class="search" style="display: none;">
							<span class="search__icon icon-search" style="opacity: 0.4;"></span>
								<input type="search" id="search" name="search" class="search__field" placeholder="Search for flight numbers, destinations, airport services or other keywords">
						</form>

						<span class="search__desktop-icon icon-search"></span>
						<span class="search__desktop-close icon-close"></span>
					</div> -->

        <nav class="navigation">
          <ul itemscope itemtype="http://www.schema.org/SiteNavigationElement">
            <li
              itemprop="name"
              v-for="(link, index) in publicSiteSettings.fields
                .headerNavigation"
              :key="index"
              :class="index"
            >
              <a
                ref=""
                :href="link.fields.mainLink?.url"
                :title="link.fields.mainLink?.name"
                :target="link.fields.mainLink?.target"
                itemprop="url"
                v-on:keyup="accessibleNav"
                >{{ link.fields.mainLink?.name }}</a
              >

              <div
                class="navigation__dropdown"
                v-if="link.fields.nestedLinks.length"
              >
                <div
                  class="navigation__dropdown__col cf"
                  v-for="(nestedLinks, index) in link.fields.nestedLinks"
                  :key="index"
                >
                  <h2 v-if="nestedLinks.fields?.linkGroupTitle">
                    {{ nestedLinks.fields?.linkGroupTitle }}
                  </h2>
                  <ul>
                    <li
                      v-for="link in nestedLinks.fields.linkGroupSublinks"
                      :key="link.id"
                    >
                      <a :target="link?.target" :href="link?.url">
                        {{ link?.name }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </nav>

        <div v-if="languages.length > 1" class="language-wrapper">
          <Select2
            :class="'select2-wrapper select2-language ' + CssClass()"
            :options="optionsLanguage"
            :settings="{
              templateResult: templateLanguage,
              escapeMarkup: (markup) => markup,
              templateSelection: templateLanguage,
              dropdownCssClass: CssClass(),
            }"
            @select="saveLanguageToSession"
            v-model="localLanguage"
          />
        </div>
      </div>
    </div>

    <div class="nav-mobile">
      <ul class="nav-mobile__top-level">
        <!-- If there are nested links show this -->
        <li
          v-for="mobileLink in publicSiteSettings.fields.headerNavigation"
          :key="mobileLink.id"
        >
          <a
            :href="mobileLink.fields.mainLink?.url"
            :target="mobileLink.fields.mainLink?.target"
            class="link no-sub"
            v-if="mobileLink.fields.nestedLinks.length"
            >{{ mobileLink.fields.mainLink?.name }}</a
          >

          <a
            :href="mobileLink.fields.mainLink?.url"
            :target="mobileLink.fields.mainLink?.target"
            class="link"
            v-else-if="!mobileLink.fields.nestedLinks.length"
            >{{ mobileLink.fields.mainLink?.name }}</a
          >

          <!-- Show the expand arrow if there are items in the nestedLinks -->
          <button
            v-if="mobileLink.fields.nestedLinks.length"
            class="expand-menu"
            v-on:click.stop="expandSubMenu"
          >
            <i class="fa fa-caret-down"></i>
          </button>

          <div
            v-if="mobileLink.fields.nestedLinks.length"
            class="nav-mobile__sub-wrap"
          >
            <ul
              class="nav-mobile__2nd-level"
              v-for="mobileSubMenu in mobileLink.fields.nestedLinks"
              :key="mobileSubMenu.id"
            >
              <li
                v-for="mobileSubMenuLinks in mobileSubMenu.fields
                  .linkGroupSublinks"
                :key="mobileSubMenuLinks.id"
              >
                <a
                  :href="mobileSubMenuLinks?.url"
                  :target="mobileSubMenuLinks?.target"
                  >{{ mobileSubMenuLinks?.name }}</a
                >
              </li>
            </ul>
          </div>
        </li>
        <!-- If there are no nested links show this -->
        <!-- <li>
          <a href="/" class="link">{{mobileLink.fields.MainLink.name}}</a>
        </li> -->
      </ul>
    </div>
  </header>
  <!--/.header -->

  <div v-if="amberAlerts.length > 0">
    <AlertBanner color="amber" :alerts="amberAlerts" />
  </div>
  <div v-if="redAlerts.length > 0">
    <AlertBanner color="red" :alerts="redAlerts" />
  </div>

  <section
    v-if="
      redAlerts.length > 0 &&
      publicSiteSettings.fields.enableRedAlertModal &&
      modalBool
    "
    class="alert-modal"
    id="alert-popup-emergency"
  >
    <div class="alert-modal__inner">
      <button @click="closeAlert" class="alert-modal__close">
        <SvgIcon name="close" />
      </button>
      <div class="alert-modal__header">
        <SvgIcon name="exclamation-triangle" />
        <span class="alert-modal__title">
          {{ redAlerts.length }} Red
          {{ redAlerts.length > 1 ? "Alerts" : "Alert" }}
        </span>
      </div>
      <ul class="alert-modal__list">
        <li
          class="alert-modal__list-item"
          v-for="alert in redAlerts"
          :key="alert.system.id"
        >
          <p class="alert-modal__alert-text">{{ alert.fields.alertText }}</p>
          <div class="alert-modal__footer">
            <a
              class="alert-modal__link"
              v-if="alert.fields.link"
              :href="alert.fields.link.url"
            >
              <span>
                {{ alert.fields.link.name }}
              </span>
              <SvgIcon name="chevron-down" class="close-icon" />
            </a>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Select2 from "vue3-select2-component";
import SvgIcon from "@/components/Icon";
import AlertBanner from "./Alerts/Alert-banner/AlertBanner.vue";
import MixedAlertModal from "./Alerts/Mixed-alert-modal/MixedAlertModal.vue";

export default {
  name: "SiteHeader",
  props: ["publicSiteSettings", "languages", "selectedLanguage"],
  components: {
    Select2,
    SvgIcon,
    AlertBanner,
    MixedAlertModal,
  },
  setup(props) {
    const navItems = props.publicSiteSettings.fields.headerNavigation.length;
    return { navItems };
  },
  data() {
    return {
      // Initialize localLanguage with the value of the prop
      showRedAlerts: false,
      showAmberAlerts: false,
      redAlerts: this.publicSiteSettings.fields.redAlerts,
      amberAlerts: this.publicSiteSettings.fields.amberAlerts,
      localLanguage: this.selectedLanguage,
      optionsLanguage: this.languages.map(({ cultureCode, cultureName }) => {
        return { id: cultureCode, text: cultureName };
      }),
      modalBool: sessionStorage.getItem("modalBool") !== "false", // Default to true if not set
    };
  },
  watch: {
    // Watch for changes in the prop and update localLanguage
    selectedLanguage(newVal) {
      this.localLanguage = newVal;
    },
    // Emit event to parent when localLanguage changes
    localLanguage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("languageChanged", newVal);
        this.saveLanguageToSession();
      }
    },
  },
  mounted() {
    this.loadLanguageFromSession();
    this.loadModalStateFromSession();
  },
  methods: {
    CssClass() {
      return (this.publicSiteSettings?.fields?.airportName || " ")
        .replace(" ", "")
        .toLowerCase();
    },
    closeAlert() {
      document.querySelector(".alert-modal").style.display = "none";
      sessionStorage.setItem("modalBool", "false");
      this.modalBool = false;
    },
    toggleRedAlerts() {
      this.showRedAlerts = !this.showRedAlerts;
    },
    toggleAmberAlerts() {
      this.showAmberAlerts = !this.showAmberAlerts;
    },
    mobileMenu() {
      let header = document.querySelector(".header");
      let button = document.querySelector(".header__hamburger");

      button.classList.toggle("open");

      if (header.classList.contains("header--menu-open")) {
        header.classList.remove("header--menu-open");
        document.querySelector("body").classList.remove("overflow-hidden");
      } else {
        header.classList.add("header--menu-open");
        document.querySelector("body").classList.add("overflow-hidden");
      }
    },
    expandSubMenu(event) {
      let expandBtn = event.currentTarget;
      expandBtn.classList.toggle("active");
    },
    accessibleNav(event) {
      let key = event.key;
      var currentFocus = document.activeElement;
      var previousEl = currentFocus.parentElement.previousSibling;

      if (key === "ArrowLeft") {
        previousEl.focus();
      }
    },
    loadModalStateFromSession() {
      const storedModalState = sessionStorage.getItem("modalBool");
      this.modalBool = storedModalState !== "false";
    },
    loadLanguageFromSession() {
      const storedLanguage = sessionStorage.getItem("selectedLanguage");
      const storedModalState = sessionStorage.getItem("isModalOpen");
      if (
        storedLanguage &&
        this.languages.some(
          (language) => language.cultureCode === storedLanguage
        )
      ) {
        this.localLanguage = storedLanguage; // Update localLanguage
      }
    },
    saveLanguageToSession() {
      sessionStorage.setItem("selectedLanguage", this.localLanguage);
    },
    templateLanguage(option) {
      if (!option.id) {
        return option.text;
      }
      return `<span class="flag ${option.id}" alt="${option.text}"></span>`;
    },
  },
};
</script>

<style lang="scss">
.language-wrapper {
  align-self: center;
  margin-top: -3px;

  @include breakpoint($desktop-sml) {
    margin-top: 0;
    margin-left: 20px;
  }

  & .select2-wrapper.select2-language {
    width: 75px !important;
    margin-right: 10px;

    & .select2-container {
      height: 39px !important;

      @include breakpoint($desktop-sml) {
        height: 37px !important;
      }

      & .select2-selection--single {
        height: 39px !important;

        @include breakpoint($desktop-sml) {
          height: 37px !important;
        }
      }

      & .select2-selection__arrow {
        width: 20px !important;
        right: 2px !important;
        height: 39px !important;

        @include breakpoint($desktop-sml) {
          height: 37px !important;
        }
      }

      & .select2-selection__rendered {
        padding-left: 5px;
        padding-top: 1px;

        @include breakpoint($desktop-sml) {
          padding-top: 0;
        }
      }
    }

    .select2-results__option {
      padding: 8px 0.5rem 8px 1rem !important;
    }
  }
}

.flag {
  width: 35px;
  height: 35px;
  display: block;

  background: {
    position: 50% 50%;
    size: contain;
    repeat: no-repeat;
  }

  &.de {
    background-image: url("~@/assets/images/flags/de.svg");
  }
  &.nl {
    background-image: url("~@/assets/images/flags/nl.svg");
  }
  &.en-us {
    background-image: url("~@/assets/images/flags/en.png");
  }
  &.ga {
    background-image: url("~@/assets/images/flags/ie.png");
  }

  &.es {
    background-image: url("~@/assets/images/flags/es.png");
  }
}

.shannonairport {
  .flag {
    &.en-us {
      background-image: url("~@/assets/images/flags/en.png");
    }
  }
}

.sanantonioairport {
  .flag {
    &.en-us {
      background-image: url("~@/assets/images/flags/en.png");
    }
  }
}

.header {
  position: relative;
  background: $header-bg-mobile;
  color: $header-color-mobile;
  height: 7.2rem;
  display: flex;

  @include breakpoint($desktop-sml) {
    height: 10rem;
    background: $header-bg;
    color: $header-color;
    align-items: center;
  }

  .logo-wrapper {
    flex-grow: 1;

    @include breakpoint($desktop-sml) {
      display: flex;
      align-items: center;
    }
  }

  &__logo {
    img {
      max-width: 150px;
      max-height: 35px;

      @include breakpoint($desktop-sml) {
        max-width: 250px;
        max-height: 60px;
      }
    }
  }

  .inner {
    @media screen and (max-width: $desktop-sml) {
      display: flex;
      align-items: center;
    }
  }

  &--wide {
    .inner {
      max-width: 144rem;
    }

    @include breakpoint($desktop-sml) {
      height: 7.2rem;

      .header__logo img {
        max-width: 150px;
        max-height: 35px;
      }

      .nav-mobile {
        display: inline-block !important;
      }
    }

    @include breakpoint($wide) {
      height: 10rem;

      .header__logo img {
        max-width: 250px;
        max-height: 60px;
      }
    }
  }

  .navigation-wrapper {
    display: flex;
    align-items: center;

    @media screen and (max-width: $desktop-sml) {
      flex-direction: row-reverse;
    }
  }

  &__hamburger {
    display: inline-block;
    z-index: 1003; // one higher than mobile menu
    text-align: center;
    background: $header-bg-mobile;
    color: $header-color-mobile;
    font-size: 1.3rem;
    width: 7.2rem;
    height: 3.2rem;

    @media screen and (max-width: $desktop-sml) {
      transform: translateY(3px);
    }

    svg {
      margin: 0 auto 1rem;
      display: block;

      path {
        fill: $header-color-mobile;
      }
    }

    // Close icon
    .close-icon {
      display: none;
    }

    // Burger Icon
    .burger-icon {
      display: block;
    }

    &.open {
      .close-icon {
        display: block;
      }
      .burger-icon {
        display: none;
      }
    }

    @include breakpoint($desktop-sml) {
      display: none;
    }
  }

  // .header-nav {
  > .inner {
    background: $header-bg-mobile;
    position: relative;
    // z-index: 1002;
    height: 7.2rem;
    display: flex;
    // justify-content: space-between;
    padding: 0 2rem;

    // @include breakpoint($desktop-sml) {
    //   height: auto;
    //   padding: 0;
    // }
  }
  // }

  &--menu-open,
  &--services-open {
    @include breakpoint($desktop-sml-and-below) {
      height: 100vh;
      overflow-y: auto;
      background: $header-bg-mobile-open;
    }
  }

  &--menu-open {
    // > .inner {
    //   @include breakpoint($desktop-sml-and-below) {
    //     // top bar plus the search block on mobile - 72+86
    //     height: 158px;
    //     background: $header-bg-mobile-open;
    //   }
    // }
    // .header__nav-search {
    //   //    background: $header-bg-mobile-open;
    //   display: none;
    //   margin: 0 -2rem;

    //   @include breakpoint($desktop-sml) {
    //     background: $header-bg;
    //   }
    // }

    // .services-bar {
    //   display: none;
    // }

    .header__hamburger {
      border-left: 1px solid $header-border;
      border-bottom: none;
      transition: none;

      &::after {
        content: "";
        background: $header-bg-mobile-open;
      }
    }
  }

  // &__search {
  //   display: none;
  //   position: relative;
  //   z-index: 1002; // one higher than mobile menu
  //   top: 7.2rem;
  //   padding: 2rem 2rem 0 2rem;
  //   border-top: 1px solid $header-border;
  //   border-bottom: 1px solid $header-border;

  //   @include breakpoint($desktop-sml) {
  //     position: absolute;
  //     right: 0;
  //     top: 0;
  //     left: 0;
  //     z-index: 2;
  //     padding-left: 18rem;
  //     display: block;
  //     border: none;
  //     padding: 0;
  //     height: $navigation-desktop;
  //   }
  // }

  // &--services-open {
  //   z-index: 2;
  //   // background: $header-bg-mobile-open;

  //   .header__nav-search {
  //     display: none;
  //   }

  //   .services-bar {
  //     display: block;
  //   }

  //   .header__hamburger {
  //     border-left: none;
  //   }

  //   .header__services {
  //     border-left: 1px solid $header-border;
  //     border-right: 1px solid $header-border;
  //     border-bottom: none;
  //     transition: none;

  //     &::after {
  //       content: "";
  //       background: $header-bg-mobile-open;
  //     }
  //   }
  // }

  // &__services {
  //   display: inline-block;
  //   position: absolute;
  //   z-index: 1002; // one higher than mobile menu
  //   text-align: center;
  //   background: $header-btn-bg;
  //   color: $header-btn-color;
  //   font-size: 1.3rem;
  //   right: 7.2rem;
  //   top: 0;
  //   width: 7.2rem;
  //   height: 7.2rem;

  //   > div {
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translateX(-50%) translateY(-50%);
  //   }

  //   span {
  //     display: block;
  //     margin: 0 auto 0.5rem;
  //     width: 24px;
  //     height: 24px;
  //     background-position: center center;
  //   }
  // }

  // &__alert {
  //   display: inline-block;
  //   position: absolute;
  //   z-index: 1002; // one higher than mobile menu
  //   text-align: center;
  //   background: $header-btn-bg;
  //   color: $header-btn-color;
  //   font-size: 1.3rem;
  //   right: 14.4rem;
  //   top: 0;
  //   width: 7.2rem;
  //   height: 7.2rem;

  //   > div {
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translateX(-50%) translateY(-50%);
  //   }

  //   i {
  //     display: block;
  //     margin: 0 auto 0.5rem;
  //     font-size: 2.2rem;
  //     color: #FFC400;
  //   }

  //   &.emergency {
  //     i {
  //       color: $alert-emergency;
  //     }
  //   }
  // }

  // &__alert ,
  // &__services,
  // &__hamburger
  // {
  //   display: block;

  //   &::after {
  //     content: none;
  //     display: block;
  //     position: absolute;
  //     z-index: 2;
  //     bottom: -1px;
  //     left: 0;
  //     right: 0;
  //     height: 1px;
  //     background: $header-bg-mobile;
  //   }

  //   @include breakpoint($desktop-sml) {
  //     display: none;
  //   }
  // }

  // &__alerts {
  //   @include breakpoint($desktop-sml) {
  //     position: absolute;
  //     height: 60px;
  //     left: 0;
  //     bottom: 0;
  //     right: 0;
  //   }
  // }

  // When the search bar is shown on desktop
  // &.search-active {
  //   border-bottom: none;

  //   .search {
  //     z-index: 1;
  //   }

  //   .header__logo {
  //     display: none;
  //   }

  //   .search__desktop-icon {
  //     display: none;
  //   }

  //   .search__desktop-close {
  //     display: block;
  //     background-size: 18px 18px;
  //     z-index: 1;

  //     path {
  //       fill: $white;
  //     }
  //   }

  //   .search__field {
  //     position: absolute;
  //     display: block;
  //     padding-left: 4.5rem;
  //   }

  //   .search__icon {
  //     top: 50%;
  //     opacity: 1 !important;
  //   }
  // }

  // Icons for the header
  // .icon-basket svg {
  //   g {
  //     stroke: $header-color;
  //   }

  //   ellipse {
  //     fill: $header-color;
  //   }
  // }

  // .icon-menu svg g {
  //   fill: $header-color;
  // }

  // .icon-search svg g {
  //   stroke: $white;

  //   @include breakpoint($desktop-sml) {
  //     stroke: $white;
  //   }
  // }
}

.navigation {
  display: none;

  @include breakpoint($desktop-sml) {
    display: flex;
    align-items: center;
    height: $navigation-desktop;
    //margin-right: 6.5rem; // add back if search is in
  }

  li {
    @include breakpoint($desktop-sml) {
      border: none;
      display: table-cell;
      vertical-align: middle;
      height: $navigation-desktop;
    }

    // When you tab to an <li></li>
    &:focus-within {
      a::before {
        content: "";
      }
    }

    // When you hover or focus a <li></li>
    &:hover,
    &:focus,
    &:focus-within {
      // Show the dropdown when on hover or focus
      .navigation__dropdown {
        display: block;
        animation: megaMenuFadeIn 0.3s forwards;
        transform: translateY(-14px);
      }

      // Show the line when you hover
      a::before {
        content: "";
      }
    }
    a {
      position: relative;
      display: block;
      @include font-size($nav-links-font-sizes);
      padding: 1.8rem 0;
      text-transform: uppercase;
      color: $header-color;
      text-decoration: none;

      @include breakpoint($desktop-sml) {
        display: inline-block;
        color: $header-color;
        padding: 0 0.7rem;
        height: $navigation-desktop;
        line-height: $navigation-desktop;
      }

      @include breakpoint($desktop-sml) {
        padding: 0 1rem;
      }

      @include breakpoint($desktop) {
        padding: 0 1.5rem;
      }

      &::before {
        display: block;
        position: absolute;
        left: 1.5rem;
        right: 1.5rem;
        bottom: 1.8rem;
        height: 2px;
        background: $header-hover-border;
      }
    }
  }

  &__dropdown {
    position: absolute;
    z-index: 9996; // Less than pinned bar, modals and cookie modal
    top: 100px; // match the links
    right: 0;
    width: 100%;
    max-width: 96rem;
    padding-top: 25px;
    display: none;
    box-shadow: 0px 11px 10px rgba(0, 0, 0, 0.2);

    &::before {
      position: absolute;
      z-index: -1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: "";
      background: $white;
    }

    @include breakpoint($tablet) {
      display: none;
    }

    h2 {
      font-size: 1.8rem;
      color: $mega-menu-heading;
      font-weight: $reg;
      margin-bottom: 3rem;
    }

    &__col {
      float: left;
      padding: 5rem 4rem;
      width: 33.333%;
      text-align: left;

      ul {
        li {
          margin-bottom: 20px;
          display: block;
          height: auto;
          text-align: left;

          a {
            color: $mega-menu-links;
            font-weight: $reg;
            font-size: 1.6rem;
            text-decoration: none;
            height: auto;
            line-height: 1;
            padding: 0;
            text-transform: none;

            &::before {
              content: none !important;
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      &:nth-child(even) {
        position: relative;

        &::before,
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 3.5rem;
          bottom: 3.5rem;
          width: 1px;
          background: $keyline;
        }

        &::before {
          left: 0;
        }

        &::after {
          right: 0;
        }
      }
    }
  }
}

.nav-mobile {
  //display: none;
  position: fixed;
  top: 55px; // match height of LA bar
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 73px; // this is the top bar and the search
  width: 100%;
  background: $header-bg-mobile-open;
  overflow-y: scroll;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s linear;

  .header--menu-open & {
    opacity: 1;
    z-index: 1001;
  }

  @include breakpoint($desktop-sml) {
    display: none !important;
  }

  &__top-level {
    > li {
      position: relative;
      border-bottom: 1px solid $header-border;

      > a {
        line-height: 55px;

        &.no-sub {
          padding-right: 72px;
        }
      }

      .expand-menu {
        width: 72px;
        height: 55px;
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 2rem;
        border-left: 1px solid $header-border;
        cursor: pointer;

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &.active {
          + .nav-mobile__sub-wrap {
            height: auto;
          }
        }
      }
    }

    a {
      display: block;
      padding-left: 20px;
      color: $header-color-mobile;
      font-weight: $light;
      line-height: 40px;
      font-size: 2rem;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }

      // &.sso-widget__btn {
      //     color: $sso-widget-highlight;
      // }

      // &.sso-widget__add-flight {
      //     color: $sso-widget-highlight;
      //     padding-left: 0;
      // }
    }
  }

  &__sub-wrap {
    // display: none;
    margin-right: -71px;
    height: 0;
    transition: height 0.5s linear;
    overflow: hidden;
    background: lighten($header-bg-mobile-open, 5%);

    a {
      font-size: 1.8rem;
    }
  }

  &__2nd-level {
    @include breakpoint($desktop-sml) {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &:not(:last-of-type) {
      @include breakpoint($desktop-sml) {
        border-bottom: 1px solid $header-border;
      }
    }
  }
}

//Animation for the on load of panel
@keyframes megaMenuFadeIn {
  0% {
    opacity: 0;
    top: 100px;
  }

  100% {
    opacity: 1;
    top: 100px;
  }
}

.alert-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alert-icon {
  font-size: 24px;
  margin-bottom: 10px;
}

.alert-message {
  font-size: 18px;
  text-align: center;
}

.alert-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}

.alert-close:hover {
  color: #ffdddd;
}

.page-content {
  padding: 20px;
}

// .alert-modal {
//   position: fixed;
//   z-index: 10000;
//   background-color: #ef4444;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   padding: 4rem 2.5rem;
//   border-radius: 4px;
//   width: 95%;
//   max-width: 60rem;

//   @include breakpoint($desktop-sml) {
//     padding: 4rem 6rem;
//   }

//   &__close {
//     position: absolute;
//     top: 1rem;
//     right: 1rem;

//     .close-icon {
//       display: none;
//     }

//     .close {
//       width: 32px;
//       height: 32px;
//       color: #ffffff;
//     }
//   }

//   &__inner {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }

//   &__section {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     gap: 1.5rem;
//     width: 100%;
//     padding: 2rem 0;
//     position: relative;

//     .exclamation-triangle {
//       width: 38px;
//       height: 38px;
//       color: #ffffff;
//     }

//     &::before {
//       content: "";
//       width: 100%;
//       height: 1px;
//       display: block;
//       background-color: #f87171;
//       position: absolute;
//       top: 0;
//     }

//     &:first-of-type {
//       &::before {
//         display: none;
//       }
//     }
//   }

//   &__message {
//     color: #ffffff;
//     font-weight: 600;
//     margin: 0;
//     text-align: center;
//   }

//   &__link {
//     display: flex;
//     align-items: center;
//     gap: 0.5rem;
//     padding: 1rem;
//     border-radius: 4px;
//     color: #ffffff;
//     font-size: 1.75rem;
//     font-weight: 600;
//     text-decoration: none;
//     background-color: #dc2626;

//     .chevron-down {
//       transform: rotate(-90deg);
//     }
//   }
// }
.alert-modal {
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rga(0, 0, 0, 0.8);
    display: block;
  }

  &__inner {
    width: 95%;
    max-width: 60rem;
    position: relative !important;
    background-color: #ef4444;
    border-radius: 4px;
    padding: 4rem 2.5rem;

    @include breakpoint($desktop-sml) {
      padding: 4rem 6rem;
    }
  }

  &__close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    color: #ffffff;

    .close {
      display: none;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 1rem;

    .exclamation-triangle {
      width: 32px;
      height: 32px;
      color: #ffffff;
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: 600;
    color: #ffffff;
  }

  &__list {
    list-style-type: disc;
    display: flex;
    flex-direction: column;
  }

  &__list-item {
    list-style-type: disc;
    margin: unset !important;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 1.25rem 0;
    border-top: 1px solid #f87171;

    &:first-of-type {
      border-top: none;
    }
  }

  &__alert-text {
    font-size: 1.5rem;
    font-weight: 600;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__link {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    border-radius: 4px;
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: 600;
    text-decoration: none;
    background-color: #dc2626;

    .chevron-down {
      transform: rotate(-90deg);
    }
  }
}
</style>
