<template>
  <div class="booking-component">
    <ProgressBar :steps="steps" />
    <div
      class="booking-component__body"
      :class="
        wizardStore.step === 0
          ? 'booking-component__body--wide'
          : 'booking-component__body--regular'
      "
    >
      <template v-for="(step, index) in steps" :key="index">
        <component
          v-if="wizardStore.step === index"
          :is="step.name"
          :componentData="step.data"
        ></component>
      </template>
      <BasketSummary :steps="steps" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.booking-component {
  width: 100%;
  max-width: 110rem;
  padding: 0 2rem;
  margin: 2rem auto 0 auto;

  @include breakpoint($desktop) {
    padding: 0;
  }

  &__body {
    @include breakpoint($desktop) {
      display: grid;
    }

    &--regular {
      grid-template-columns: 1fr 330px;
      gap: 2rem;
    }

    &--wide {
      grid-template-columns: 1fr;
    }
  }
}
</style>

<script>
import { useBookingWizardStore } from "../stores/bookingwizardstore";
import MockStep1 from "@/components/BookingSteps/MockStep1.vue";
import MockStep2 from "@/components/BookingSteps/MockStep2.vue";
import MockStep3 from "@/components/BookingSteps/MockStep3.vue";
import BookingDetails from "@/components/BookingSteps/BookingDetails.vue";
import BasketSummary from "@/components/BookingSteps/BasketSummary.vue";
import ProgressBar from "@/components/BookingSteps/ProgressBar.vue";
import AncillaryProducts from "@/components/BookingSteps/AncillaryProducts.vue";

export default {
  name: "BookingComponent",
  props: ["componentData"],
  components: {
    MockStep1,
    MockStep2,
    MockStep3,
    BookingDetails,
    BasketSummary,
    ProgressBar,
    AncillaryProducts,
  },
  setup() {
    const wizardStore = useBookingWizardStore();
    return {
      wizardStore,
    };
  },
  data() {
    const steps = [];
    for (var i = 0; i < this.componentData.fields.steps.length; i++) {
      var step = {
        name: this.componentData.fields.steps[i].fields.componentName,
        data: this.componentData.fields.steps[i],
      };
      steps[i] = step;
    }
    return {
      steps,
    };
  },
};
</script>
