<template>
    <section v-if="componentData.fields.aPIKey">
        <body>
            <div id="aamap" style="position: relative; width: 100%; height: 600px; overflow: hidden; margin: 0; padding: 0;"></div>
        </body>
    </section>
    <div v-else style="font-size: x-large; text-align: center; margin: 2rem;">
        Please enter a valid API key for the drone safety map IFrame.
    </div>
  </template>
  
  <script>
import $ from 'jquery';

export default {
    name: "DroneMapComponent",
    props: ["componentData"],
    data() {
      return {
            apiKey: this.componentData.fields.aPIKey
        };
    },
  mounted() {
    this.initializeMap();
  },
  methods: {
    initializeMap() {
      // Ensure that the DOM is ready before executing the map initialization
      $(document).ready(() => {
        /* eslint-disable */
        aa.initialize({
            /* eslint-enable */
                "target": "aamap",
                "baseUrl": "https://dronesafetymap.com",
                "defaultLocation": {
                latitude: 53.3351164,
                longitude: -2.8523459
                },
                "features": [
                ],
                "authDetails": {
                    "apiKey": this.apiKey,
                }
        });
      });
    }
  }
};
</script>
  