<template>
  <div class="timeline-taxi timeline-marker">
    <h3>
      <span v-if="componentData.fields.icon != null">
        <img :src="componentData.fields.icon.fields.umbracoFile.src" />
      </span>
      {{ componentData.fields.title }}
    </h3>
    <div class="timeline-taxi-form">
      <div class="grid">
        <ul class="grid-radio">
          <li class="radio">
            <input
              type="radio"
              name="taxisTripTypeInline"
              id="taxisTripTypeInlineReturn"
              value="RTN"
              checked="checked"
              v-on:click="enableReturnOptions()"
            />
            <label for="taxisTripTypeInlineReturn">Return</label>
          </li>
          <li class="radio">
            <input
              type="radio"
              name="taxisTripTypeInline"
              id="taxisTripTypeInlineSingleFrom"
              value="SGO"
              v-on:click="hideReturnOptions()"
            />
            <label for="taxisTripTypeInlineSingleFrom"
              >Single from Airport</label
            >
          </li>
          <li class="radio">
            <input
              type="radio"
              name="taxisTripTypeInline"
              id="taxisTripTypeInlineSingleTo"
              value="SGI"
              v-on:click="singleToAirport()"
            />
            <label for="taxisTripTypeInlineSingleTo">Single to Airport</label>
          </li>
        </ul>
      </div>

      <div class="grid">
        <div class="grid__full">
          <label for="pickUpLocation">{{ pickUpLocationTile }}</label>
          <Select2
            name="pickUpLocation"
            :options="airports"
            :settings="{
              placeholder: 'Please select',
            }"
            v-model="pickUpLocation"
          />
        </div>
      </div>
      <div class="grid">
        <div class="grid__half">
          <label for="pickUpDate">OUTBOUND DATE</label>
          <DatePicker
            v-model="pickUpDate"
            :min-date="new Date()"
            class="datepicker"
            :masks="{ input: displayFormat }"
            :popover="{ visibility: 'focus' }"
            is-required
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input :value="inputValue || `Date`" v-on="inputEvents" />
            </template>
          </DatePicker>
        </div>
        <div class="grid__half">
          <label for="exitTime">{{ this.labels.labelTime }}</label>
          <div class="grid-times">
            <div class="col-time-hours">
              <label for="pickUpHour" class="hiddenFromView"
                >Pick-up time hours
              </label>
              <Select2
                name="pickUpHour"
                :options="[
                  '00',
                  '01',
                  '02',
                  '03',
                  '04',
                  '05',
                  '06',
                  '07',
                  '08',
                  '09',
                  '10',
                  '11',
                  '12',
                  '13',
                  '14',
                  '15',
                  '16',
                  '17',
                  '18',
                  '19',
                  '20',
                  '21',
                  '22',
                  '23',
                ]"
                v-model="pickUpHour"
              />
            </div>
            <div class="col-time-mins">
              <label for="pickUpMinute" class="hiddenFromView"
                >Pick-up time minutes</label
              >
              <Select2
                name="pickUpMinute"
                :options="['00', '15', '30', '45']"
                v-model="pickUpMinute"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="grid" v-if="showReturnOptions">
        <div class="grid__half">
          <label for="dropOffDate">RETURNING DATE</label>
          <DatePicker
            v-model="dropOffDate"
            :min-date="new Date()"
            class="datepicker"
            :masks="{ input: displayFormat }"
            :popover="{ visibility: 'focus' }"
            is-required
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input :value="inputValue || `Date`" v-on="inputEvents" />
            </template>
          </DatePicker>
        </div>
        <div class="grid__half">
          <label for="entryTime"> {{ this.labels.labelTime }} </label>
          <div class="grid-times">
            <div class="col-time-hours">
              <label for="dropOffHour" class="hiddenFromView"
                >Drop-off time hours
              </label>
              <Select2
                name="dropOffHour"
                :options="[
                  '00',
                  '01',
                  '02',
                  '03',
                  '04',
                  '05',
                  '06',
                  '07',
                  '08',
                  '09',
                  '10',
                  '11',
                  '12',
                  '13',
                  '14',
                  '15',
                  '16',
                  '17',
                  '18',
                  '19',
                  '20',
                  '21',
                  '22',
                  '23',
                ]"
                v-model="dropOffHour"
              />
            </div>
            <div class="col-time-mins">
              <label for="dropOffMinute" class="hiddenFromView">
                >Drop-off time minutes</label
              >
              <Select2
                name="dropOffMinute"
                :options="['00', '15', '30', '45']"
                v-model="dropOffMinute"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="grid__third">
          <label for="adults"> {{ this.labels.labelAdults }} </label>
          <Select2 :options="['1', '2', '3', '4']" v-model="adults" />
        </div>
        <div class="grid__third">
          <label for="children"> {{ this.labels.labelChildren }} </label>
          <Select2 :options="['0', '1', '2', '3', '4']" v-model="children" />
        </div>
        <div class="grid__third">
          <label for="infants"> {{ this.labels.labelInfants }} </label>
          <Select2 :options="['0', '1', '2', '3', '4']" v-model="infants" />
        </div>
      </div>
    </div>

    <a href="#" @click="getURLPOST()" class="btn btn--primary">
      {{ this.labels.buttonBook }}
    </a>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import axios from "axios";
import dayjs from "dayjs";

export default {
  name: "TimelineTaxiPanel",
  props: ["componentData"],
  components: {
    Select2,
    DatePicker,
  },
  data() {
    return {
      airports: [],
      pickUpDate: "",
      dropOffDate: "",
      displayFormat: "DD-MM-YYYY",
      pickUpHour: "00",
      pickUpMinute: "00",
      dropOffHour: "00",
      dropOffMinute: "00",
      adults: "1",
      children: "0",
      infants: "0",
      showReturnOptions: true,
      pickUpLocationTile: "WHAT AIRPORT DO YOU ARRIVE AT?",
      tripType: "1",
    };
  },
  mounted() {
    this.loadLocations();
  },
  methods: {
    hideReturnOptions() {
      this.showReturnOptions = false;
      this.pickUpLocationTile = "WHAT AIRPORT DO YOU ARRIVE AT?";
      this.tripType = "2";
    },
    singleToAirport() {
      this.pickUpLocationTile = "WHAT AIRPORT DO YOU DEPART AT?";
      this.tripType = "3 q";
    },
    enableReturnOptions() {
      this.showReturnOptions = true;
      this.pickUpLocationTile = "WHAT AIRPORT DO YOU ARRIVE AT?";
      this.tripType = "1";
    },
    formatDateTime(incomingDate) {
      return dayjs(incomingDate).format(this.displayFormat);
    },
    loadLocations() {
      this.webpage = this.$route.params.slug
        ? "/" + this.$route.params.slug.replace(/\/?$/, "/")
        : "/";
      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/AirportsApi/GetAirportsLite?url=/airports/&iataCode=${process.env.VUE_APP_IATACODE}&cultureCode=${this.selectedLanguage}`;
      axios
        .get(apiUrl)
        .then((response) => {
          this.airports = response.data.airports;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getURLPOST() {
      const pickUpTimeString =
        this.pickUpHour + ":" + this.pickUpMinute + ":00";
      const dropOffTimeString =
        this.dropOffHour + ":" + this.dropOffMinute + ":00";
      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL + `/api/BookTaxiApi/GetTaxi`;
      axios({
        method: "post",
        url: apiUrl,
        headers: {},
        data: {
          pickUpLocation: this.pickUpLocation,
          dropOffLocation: this.pickUpLocation,
          pickUpDate: this.formatDateTime(this.pickUpDate),
          pickUpTime: pickUpTimeString,
          returnDate: this.formatDateTime(this.dropOffDate),
          returnTime: dropOffTimeString,
          adults: this.adults,
          children: this.children,
          infants: this.infants,
          iataCode: process.env.VUE_APP_IATACODE,
          cultureCode: this.selectedLanguage,
          tripType: this.tripType,
        },
      })
        .then((response) => {
          this.taxiURL = response.data;
          window.open(this.taxiURL);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline-taxi {
  width: 100%;
  padding: 3rem;
  background-color: $skin-colour-primary;
  color: $white;

  h3 {
    color: $white !important;
    position: relative;
    align-items: center;
    display: flex;
    font-family: $futura;
    font-weight: 200;

    span {
      display: block;
      width: 4rem;
      height: 4rem;
      margin: 0 1rem 0 0;
      border-radius: 50%;
      background: $skin-colour-primary-bright;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: block;
        max-width: 2.5rem;
        max-height: 2.5rem;
      }
    }
  }

  .timeline-taxi-form {
    margin-top: 30px;

    .grid {
      margin-bottom: 30px;
    }

    label {
      font-size: 1.5rem;
      color: $white;
    }

    .grid-times {
      display: flex;

      .col-time-hours,
      .col-time-mins {
        width: 50%;
      }
    }

    .grid__half:nth-child(2) {
      margin-top: 30px;

      @include breakpoint($tablet) {
        margin-top: 0;
      }
    }

    .grid__third {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      @include breakpoint($tablet) {
        margin-bottom: 0;
      }
    }

    .grid-radio {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include breakpoint($tablet) {
        flex-direction: row;
      }
    }

    .hiddenFromView {
      //https://stackoverflow.com/a/26032207
      position: absolute !important; /* Outside the DOM flow */
      height: 1px;
      width: 1px; /* Nearly collapsed */
      overflow: hidden;
      clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
      clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
    }
  }

  .btn {
    width: 100%;
  }

  .btn--primary {
    margin-top: 20px;
  }

  @include breakpoint($tablet) {
    .btn {
      width: auto;
    }
  }
}
</style>
