import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import { createPinia } from 'pinia'
import App from "./App.vue";
import router from "./router";
import store from "./store";

createApp(App)
    .use(router).
    use(createHead()).
    use(store).
    use(createPinia())
.mount("#app");
